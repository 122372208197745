import React, { useCallback, useMemo } from 'react';
import { ContryCodeTruct, countryCodes, useStyles } from './Settings.utils';
import CustomizedButton from '../../components/CustomizedButton/CustomizedButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import usePostMessageToWidget from '../../hooks/usePostMessageToWidget';
import Config from '../../config.json';
import { UserStatusData } from '../../store/types/messagesToWidget';
import { ReduxState } from '../../store/types/store';
import { getAlis } from '../../helpers/AliasHelper';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const Settings = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {user} = useSelector(
        (state: ReduxState) => state.auth
    );
    const defaultCountry = useSelector(
        (state: ReduxState) => state.settings.defaultCountry ?? ''
    );

    const logOut = useCallback(() => {
        dispatch(actions.logOut.request({
            callback: () => {
                window.location.replace(Config.BASE_PATH);
                // eslint-disable-next-line react-hooks/rules-of-hooks
                usePostMessageToWidget({
                    type: "USER_STATUS",
                    data: {
                        isLoggedIn: false,
                        userMail: null,
                        register: false,
                        aliace: '',
                        initial: '',
                        isCalling: false,
                    } as UserStatusData
                });
            }
        }));
    }, [dispatch]);

    const displayName = useMemo(() => {
        return (user ? getAlis(user) : undefined)
            ?? t('screens:contacts.unnamed');
    }, [user, t]);

    const handleChangeCountry = useCallback((e: any) => {
        dispatch(actions.setDefaultCountry.request({
            value: e.target.value
        }));
    }, [dispatch]);

    return (
        <div className={classes.container}>
            {user && (<div className={classes.myInfoContainer}>
                <div className={classes.myInfoTitle}>{t('screens:settings.myAccount')}:</div>
                <div className={classes.userCardDetailsContainer}>
                    <div className={classes.userCardContactNameContainer}>
                        {displayName}
                    </div>
                    {user?.numbers?.ext?.length && (
                        <div className={classes.userCardContactNumberContainer}>
                            {user?.numbers?.ext?.length ? (t('screens:contacts.ext') + ': ' + user.numbers.ext) : ''}
                        </div>
                    )}
                    {user?.balance && (<div className={classes.userCardBalanceContainer}>
                        {user?.balance?.amount?.toFixed(2)} {user?.balance?.currency}
                    </div>)}
                </div>
            </div>)}
            <div className={classes.dropdownContainer}>
                <div className={classes.myInfoTitle} style={{marginBottom: 8}}>
                    {t('screens:settings.countryForLocalNumbers')}:
                </div>
                <Select
                    value={defaultCountry}
                    onChange={handleChangeCountry}
                    size='small'
                >
                    <MenuItem value=""><em>None</em></MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    {countryCodes.map((e: ContryCodeTruct) => {
                        return (<MenuItem value={e.code} key={'indx_country_' + e.code}>{e.name}</MenuItem>);
                    })}
                </Select>
            </div>
            <CustomizedButton
                label={t('screens:settings.logout')}
                className={classes.logoutButton}
                variant={'outlined'}
                onClick={logOut}
            />
        </div>
    );
};

export default Settings;