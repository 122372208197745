import {put, takeLatest} from 'redux-saga/effects';
import * as actions from '../../actions';
import { ActionType } from 'typesafe-actions';
import { AxiosResponse } from 'axios';
import { GetRegistrationStatusPayload, User } from '../../actions/payloads';
import { api } from '../../services/axios';
import { EndPoints } from '../../endpoints';
import { generateRandomString } from '../../../helpers/StringHelper';
import { md5 } from 'js-md5';

export function* signIn(action: ActionType<typeof actions.logIn.request>) {
    const {login, password} = action.payload;
    try {
        const newRequestId = generateRandomString(16);
        const data = {
            "identifier": newRequestId,
            "login": login,
            "password": password,
            "type": "web"
        };

        const res: AxiosResponse<{
            token: string,
        }> = yield api.post(EndPoints.SignIn, data);

        localStorage.setItem('request_id', newRequestId);
        localStorage.setItem('access_token', res.data.token);
        localStorage.setItem('user_login', login);
        localStorage.setItem('session_expired', 'false');

        yield put(
            actions.logIn.success({
                access_token: res.data.token
            }),
        );
        yield put(actions.setNavBarCurrentIndex.request(1));
    } catch (err) {
        // @ts-ignore
        yield put(actions.logIn.failure(err));
    }
}

export function* getRegistrationStatus() {
    try {
        const res: AxiosResponse<GetRegistrationStatusPayload> 
            = yield api.get(EndPoints.Status);
        
        yield put(
            actions.getRegistrationStatus.success(res.data),
        );
    } catch (err) {
        // @ts-ignore
        yield put(actions.getRegistrationStatus.failure(err));
    }
}

export function* logOut(action: ActionType<typeof actions.logOut.request>) {
    try {
        yield api.delete(EndPoints.LogOut);
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_login');
        localStorage.removeItem('request_id');
        yield put(actions.logOut.success());
        action.payload?.callback?.();
    } catch (err) {
        //@ts-ignore
        console.error(err);
    }
}

export function* getUserDetails() {
    try {
        const res: AxiosResponse<User> = yield api.get(EndPoints.User);

        const user: User = res.data;
        if(user) {
            user.email_md5 = (user?.email?.length ?? 0) > 0 
                ? md5(user.email ?? '')
                : undefined;
        }

        yield put(
            actions.getUserDetails.success(user),
        );
    } catch (err) {
        // @ts-ignore
        yield put(actions.getUserDetails.failure(err));
    }
}

export function* setRegistrationStatus(action: ActionType<typeof actions.setRegistrationStatus.request>) {
    try {
        if(!action?.payload?.doNotExecuteApi) {
            yield api.patch(EndPoints.Status, action.payload);
        }
        
        yield put(
            actions.setRegistrationStatus.success(action.payload),
        );
    } catch (err) {
        // @ts-ignore
        yield put(actions.setRegistrationStatus.failure(err));
    }
}

export const authSaga = [
    takeLatest(actions.logIn.request, signIn),
    takeLatest(actions.getRegistrationStatus.request, getRegistrationStatus),
    takeLatest(actions.logOut.request, logOut),
    takeLatest(actions.getUserDetails.request, getUserDetails),
    takeLatest(actions.setRegistrationStatus.request, setRegistrationStatus),
];
