import * as Yup from 'yup';
import i18n from '../services/i18n';

export type SignInForm = {
    login: '',
    password: '',
};

export const initLoginFormData: SignInForm = {
    login: '',
    password: '',
};

const requiredFieldError = i18n.t('errors:common.emptyInput');

export const validationSchema = Yup.object().shape({
    login: Yup.string()
        .nonNullable(requiredFieldError)
        .required(requiredFieldError),
    password: Yup.string()
        .nonNullable(requiredFieldError)
        .required(requiredFieldError)
});