import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../store';
import { ReduxState } from '../../store/types/store';
import { useStyles } from './Contacts.utils';
import ContactInfoButton from '../../assets/contacts__info_icon.svg';
import { Contact } from '../../store/actions/contacts/payload';
import { useTranslation } from 'react-i18next';
import CustomizedTextField from '../../components/CustomizedTextField/CustomizedTextField';
import SearchIcon from '@mui/icons-material/Search';
import Loader from '../../components/Loader/Loader';
import classNames from 'classnames';
import CallButton from '../../assets/call_button.svg';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import NoData from '../../components/NoData/NoData';
import { getAliasForEntity } from '../../helpers/AliasHelper';

const Contacts = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const [searchText, setSearchText] = useState<string>('');
    const {contacts, isLoadingContacts } = useSelector(
        (state: ReduxState) => state.contacts
    );

    useEffect(() => {
        dispatch(actions.getContacts.request());
    }, [dispatch]);

    const hideAllDetails = useCallback(() => {
        const toClose = document.getElementsByClassName(classes.diplayItem) ?? [];
        //@ts-ignore
        for(const itm of toClose) {
            itm.classList.remove(classes.diplayItem);
        }
    }, [classes]); 

    const showDetails = useCallback((detaildId: string) => {
        const detailsObj = document.getElementById(detaildId);
        if(detailsObj) {
            if(detailsObj.classList.contains(classes.diplayItem)) 
            {
                detailsObj.classList.remove(classes.diplayItem);
            }
            else
            {
                hideAllDetails();
                detailsObj.classList.add(classes.diplayItem);
            }
        }
    }, [classes, hideAllDetails]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearchText(e.target?.value ?? '');
    }, []);

    const filteredResults = useMemo(() => {
        if(!searchText?.length || !contacts?.length) {
            return contacts;
        }
        const pattern = searchText.trim();
        const regex = new RegExp(pattern, "gmi");
        return contacts.filter(e => 
            (e.alias_name?.match(regex)?.length ?? 0) > 0 || 
            (e.displayName?.match(regex)?.length ?? 0) > 0 || 
            (e.numbers?.ext?.match(regex)?.length ?? 0) > 0 || 
            (e.numbers?.main?.match(regex)?.length ?? 0) > 0
        );
    }, [searchText, contacts]);

    const makeCall = useCallback((contact: Contact) => {
        dispatch(actions.setIsCalling.request({
            isCalling: true,
            number: contact.numbers?.ext ?? contact?.numbers?.main
        }));
        dispatch(actions.setNavBarCurrentIndex.request(2));
    }, [dispatch]);

    const showActionButtons = useCallback((parentId: string) => {
        const parentObj = document.getElementById(parentId);
        if(parentObj) {
            //@ts-ignore
            parentObj.lastChild.classList.add(classes.showButtons);
        }
    }, [classes]);

    const hideActionButtons = useCallback((parentId: string) => {
        const parentObj = document.getElementById(parentId);
        if(parentObj?.lastChild) {
            //@ts-ignore
            parentObj.lastChild.classList.remove(classes.showButtons);
        }
    }, [classes]);

    return (
        <div className={classes.rootContainer}>
            <div className={classes.searchContainer}>
                <CustomizedTextField
                    id={'login'}
                    placeholder={t('screens:contacts.search')}
                    maxLength={128}
                    className={classes.searchTextBox}
                    onChange={handleChange}
                    dataQa="contacts-search"
                    disableCounter
                    iconPosition='start'
                    icon={<SearchIcon />}
                />
            </div>
            {
                isLoadingContacts ? (
                    <div className={classes.contactsListContainer} key="contacts_loader_id">
                        <Loader />
                    </div>
                )
                : (contacts?.length ?? 0) === 0
                ? ( <div className={classes.contactsListContainer} key="no_data_id">
                        <NoData />
                </div>)
                : (<div className={classes.contactsListContainer} key="contacts_container_id">
                        {filteredResults?.map((contact: Contact, indx: number) => (<div
                            key={'contact_card_container_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx}
                        >
                            <div className={classes.contactCard}
                                key={'contact_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx}
                                id={'contact_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx}
                                onMouseEnter={() => showActionButtons('contact_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx)}
                                onMouseLeave={() => hideActionButtons('contact_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx)}
                            >
                                <div className={classes.contactCardContactInfoContainer}>
                                    <div className={classes.contactCardAvatarContainer}>
                                        <div className={classes.contactCardAvatarDiv}>
                                            <img 
                                                src={'https://www.gravatar.com/avatar/' + contact.email_md5 + '?s=40&r=g&d=blank'}
                                                className={classes.contactCardAvatar}
                                                alt={"contact-gravatar-button" + indx}
                                            />
                                        </div>
                                        <div className={classes.contactCardAvatarAbbreviation}>
                                            <div>{getAliasForEntity(contact)}</div>
                                        </div>
                                        <div className={classes.contactStatusContainer}>
                                            <div className={classNames(classes.contactStatus, contact.sip_status === 'registered' 
                                                    ? classes.contactStatusRegistered 
                                                    : classes.contactStatusNotRegistered )}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.contactCardDetailsContainer}>
                                        <div className={classNames(classes.contactCardContactNameContainer, 
                                            (contact?.displayName?.length ?? 0) > 32 && classes.contactCardContactNameContainerTooLong
                                        )}>
                                            {contact?.displayName}
                                        </div>
                                        {
                                            contact?.numbers?.ext?.length && (
                                                <div className={classes.contactCardContactNumberContainer}>
                                                    {t('screens:contacts.ext') + ': ' + contact.numbers.ext}
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className={classes.contactCardActionButtonsContainer}>
                                    <div className={classes.infoButton} onClick={() => makeCall(contact)}>
                                        <img src={CallButton} alt="call-button" className='callButton' />
                                    </div>
                                    <div className={classes.infoButton} onClick={() => showDetails('contact_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx + '_details')}>
                                        <img src={ContactInfoButton} alt={"contact-info-button" + indx} />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.contactCardDetails}
                                key={'contact_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx + '_details'}
                                id={'contact_' + (contact.numbers?.ext ?? contact.numbers?.main) + "_" + indx + '_details'}
                            >
                                <div className={classes.contactCardDetailsRow}>
                                    <div className={classes.contactCardDetailsRowName}>{t('screens:contacts.ext')}: </div>
                                    <div className={classNames(classes.contactCardDetailsRowValue, classes.detailsRowWithButton)}>
                                        <div>
                                            {contact?.numbers?.ext?.length ? contact.numbers.ext : '-'}
                                        </div>
                                        <div onClick={hideAllDetails}>
                                            <CancelOutlinedIcon className={classes.closeDetailsButton}  />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.contactCardDetailsRow}>
                                    <div className={classes.contactCardDetailsRowName}>{t('screens:contacts.number')}: </div>
                                    <div className={classes.contactCardDetailsRowValue}>{contact?.numbers?.main?.length ? contact.numbers.main : '-'}</div>
                                </div>
                                <div className={classes.contactCardDetailsRow}>
                                    <div className={classes.contactCardDetailsRowName}>{t('screens:contacts.email')}: </div>
                                    <div className={classes.contactCardDetailsRowValue}>{contact?.email?.length ? contact.email : '-'}</div>
                                </div>
                                <div className={classes.contactCardDetailsRow}>
                                    <div className={classes.contactCardDetailsRowName}>{t('screens:contacts.mobile')}: </div>
                                    <div className={classes.contactCardDetailsRowValue}>-</div>
                                </div>
                                <div className={classes.contactCardDetailsRow}>
                                    <div className={classes.contactCardDetailsRowName}>{t('screens:contacts.company')}: </div>
                                    <div className={classes.contactCardDetailsRowValue}>-</div>
                                </div>
                            </div>
                        </div>))}
                    </div>
                )
            }
        </div>
    );
};

export default Contacts;