import { DialpadProps, useStyles } from "./Dialpad.utils";
import Padkey from "./Padkey/Padkey";

const Dialpad: React.FC<DialpadProps> = ({
    clicked,
    replaceLastChar,
    allowAD = false,
    keyUp
}) => {
    const { classes } = useStyles();
    
    return (<div className={classes.container}>
        <div className={classes.numbersRow}>
            <Padkey chars={allowAD ? "1abc" : "1"} clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars={allowAD ? "2d" : "2"} clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars="3" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
        </div>
        <div className={classes.numbersRow}>
            <Padkey chars="4" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars="5" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars="6" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
        </div>
        <div className={classes.numbersRow}>
            <Padkey chars="7" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars="8" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars="9" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
        </div>
        <div className={classes.numbersRow}>
            <Padkey chars="*" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars={allowAD ? "0" : "0+"} clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
            <Padkey chars="#" clickedChar={clicked} replaceLastChar={replaceLastChar} keyUp={keyUp} />
        </div>
    </div>);
}

export default Dialpad;