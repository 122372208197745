import {applyMiddleware, combineReducers, createStore} from 'redux';
// @ts-ignore
import {promiseMiddleware} from '@adobe/redux-saga-promise';
import createSagaMiddleware from 'redux-saga';
import {all} from 'redux-saga/effects';

//Reducers
import auth from './reducers/auth/reducer';
import keypad from './reducers/keypad/reducer';
import contacts from './reducers/contacts/reducer';
import recentCalls from './reducers/recentCalls/reducer';
import settings from './reducers/settings/reducer';

//Sagas
import {authSaga} from './sagas/auth/saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import { contactsSaga } from './sagas/contacts/saga';
import { callsSaga } from './sagas/recentCalls/saga';
import { settingsSaga } from './sagas/settings/saga';

const sagaMiddleware = createSagaMiddleware();

//@ts-ignore
export const store = createStore(
    // || (() => null), <- this is necessary for testing
    combineReducers({
        auth: auth || (() => null),
        keypad: keypad || (() => null),
        contacts: contacts || (() => null),
        recentCalls: recentCalls || (() => null),
        settings: settings || (() => null),
    }),
    composeWithDevTools(applyMiddleware(promiseMiddleware, sagaMiddleware)),
);

function* saga() {
    yield all([
        ...authSaga,
        ...contactsSaga,
        ...callsSaga,
        ...settingsSaga
    ]);
}

sagaMiddleware.run(saga);
