import React from 'react';
import classNames from 'classnames';
import { makeStyles } from "tss-react/mui";
import { useTranslation } from 'react-i18next';

type NoDataProps = {
    customClass?: string;
    customText?: string;
};

export const useStyles = makeStyles()(() => {
    return {
        rootContainer: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        internalContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: 'fit-content',
            height: '100%',
        },
        textContainer: {
            maxWidth: '100%',
            width: 'fit-content',
            height: 'fit-content',
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '18.76px',
            textAlign: 'center',
            color: 'rgba(44, 40, 37, 1)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        }
    };
});

const NoData: React.VFC<NoDataProps> = ({
    customClass,
    customText
}) => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    
    return (<div className={classNames(classes.rootContainer, customClass)}>
        <div className={classes.internalContainer}>
            <div className={classes.textContainer}>
                {customText ?? t('common:noData')}
            </div>
        </div>
    </div>);
};

export default NoData;