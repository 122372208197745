import { makeStyles } from "tss-react/mui";
import { Colors } from "../../styles/Colors";
import { KeypadForm } from "../../store/actions/payloads";
import * as Yup from 'yup';

export const initKeyPadData: KeypadForm = {
    value: ''
};

export const validationSchema = Yup.object().shape({
    value: Yup.string()
        .nullable()
        .notRequired(),
});

export const useStyles = makeStyles()(() => {
    return {
        container: {
            background: Colors.White,
            width: 'calc(100% - 48px)',
            height: 'calc(100% - 24px)',
            padding: '8px 24px 16px 24px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        inputLabelContainer: {
            width: '100%',
            height: 53,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        inputLabel: {
            fontFamily: 'Montserrat',
            fontSize: 24,
            height: 24,
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.15000000596046448px',
            textAlign: 'center',
            color: 'rgba(61, 91, 104, 1)',
            maxWidth: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        actionButtons: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: 'calc(100% - 57px)',
            height: 'fit-content',
        },
        actionButtonsOnPadKey: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            width: 'calc(100% - 57px)',
            height: 'fit-content',
        },
        callButton: {
            width: 56,
            height: 56,
            cursor: 'pointer',
        },
        callButtonAnswer: {
            marginRight: 60
        },
        actionButton: {
            cursor: 'pointer',
            marginLeft: 24,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            userSelect: 'none',
        },
        disabledCallButton: {
            cursor: 'not-allowed'
        },
        dialPadContainer: {
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
        },
        callingStatusLabel: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '16px',
            textAlign: 'center',
            color: 'rgba(139, 157, 164, 1)',
            marginTop: 13,
        },
        keysOnCalling: {
            width: '100%',
            height: 166,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
        },
        actionButtonsOnCalling: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            height: 'fit-content',
        },
        backButton: {
            color: 'rgba(61, 91, 104, 0.6)',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            '& svg': {
                width: 24,
                height: 24,
                '& path': {
                    color: 'rgba(61, 91, 104, 0.6)',
                    fillOpacity: 0.8,
                },
            },
        },
        internalCallButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        customError: {
            fontSize: 14,
            fontWeight: 400,
            color: 'rgba(61, 91, 104, 0.6)',
        },
        backspaceButtonContainer: {
            marginLeft: 16,
            marginRight: 8,
        },
        backspaceButton: {
            width: '24px !important',
            height: '24px !important',
            background: 'transparent !important',
            border: 'none !important',
        },
        buttonDisabled: {
            opacity: 0.64,
        }
    };
});

export function callDuration(dtStart: number) {
    if(!dtStart) {
        return '00:00';
    }
    const dt = new Date().valueOf();
    const dif = (dt - dtStart);
    const totalSeconds = (dif % 1000 >= 500
        ? Math.trunc(dif / 1000) + 1
        : Math.trunc(dif / 1000));
    const minutes = (totalSeconds - (totalSeconds % 60)) / 60;
    if(minutes < 60) {
        return minutes.toFixed(0).padStart(2, '0')
            + ':'
            + (totalSeconds % 60).toFixed(0).padStart(2, '0');
    }
    const restMinutes = minutes % 60;
    const hours = Math.trunc((minutes - restMinutes) / 60);
    return hours.toFixed(0).padStart(2, '0') + ':'
        + restMinutes.toFixed(0).padStart(2, '0')
        + ':'
        + (totalSeconds % 60).toFixed(0).padStart(2, '0');
}