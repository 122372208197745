import {put, takeLatest} from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as actions from '../../actions';

export function* getDefaultCountry(action: ActionType<typeof actions.getDefaultCountry.request>) {
    const itm = localStorage.getItem('default_dial_country_code');
    yield put(actions.getDefaultCountry.success({
        value: itm
    }));
}

export function* setDefaultCountry(action: ActionType<typeof actions.setDefaultCountry.request>) {
    if(action.payload.value) {
        localStorage.setItem('default_dial_country_code', action.payload.value);
    }
    yield put(actions.setDefaultCountry.success(action.payload));
}

export function* setDefaultCountryInitially(action: ActionType<typeof actions.setDefaultCountryInitially.request>) {
    const itm = localStorage.getItem('default_dial_country_code');
    if(!itm && action.payload.value) {
        localStorage.setItem('default_dial_country_code', action.payload.value);
        yield put(actions.setDefaultCountryInitially.success(action.payload));
    } else {
        yield put(actions.setDefaultCountryInitially.success({
            value: itm
        }));
    }
}

export const settingsSaga = [
    takeLatest(actions.getDefaultCountry.request, getDefaultCountry),
    takeLatest(actions.setDefaultCountry.request, setDefaultCountry),
    takeLatest(actions.setDefaultCountryInitially.request, setDefaultCountryInitially),
];
