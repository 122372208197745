
import PeerConnection from './peer_connection';
import Config from '../config.json';
import { MessageFromWidget } from '../store/types/messagesToWidget';

const WebtritSignaling = require('@webtrit/webtrit-signaling');

const url = Config.SIGNALING_PROTOCOL_URL;

const c_call_infinity = -1;
const delay_before_pcGetStat = 2000;
const c_call_disable = -2;

const constraints = {
    audio: {
        autoGainControl: false,
        echoCancellation: false,
        latency: 0,
        noiseSuppression: false,
        volume: 1.0
    },
    video: null
};

export type IncomingCallData = {
    callerName: string;
    line: number;
    call_id: any;
    sdp: string | null;
};

export default class Signaling {
    webtritSignalingClient: any = null;
    call_id: any = null;
    line: number = 0;
    call_enable: boolean = false;
    call_outgoing: boolean | null = null;
    outgoing_duration: any = null;
    outgoing_deviation: any =  null;
    call_timer: any = null;
    pc: any = null;
    stats_interval: any = null;
    statusSpan: string = 'init';
    volume: number = 1.0;
    statusCallback?: (self: any, status: any, additionalInfo: any) => void;
    id: any;
    
    numberToCall = (new URLSearchParams(window.location.search)).get('number');
    token: string | null = null;

    constructor(_token: string, _statusCallback: (self: any, status: any, additionalInfo: any) => void) {
        this.token = _token;
        this.statusCallback = _statusCallback;

        this.updateStatus('Start');
        this.webtritSignalingClient = new WebtritSignaling({
            eventCallback: async (event: any) => {
                switch (event.type) {
                    case 'in':
                        console.log('IncomingCall event', event);
                        const callerName = event.caller_display_name?.length > 0 
                            ? event.caller_display_name
                            : event.caller;
                        const data = {
                            callerName: callerName,
                            line: event.line,
                            call_id: event.call_id,
                            sdp: event.jsep
                        } as IncomingCallData;
                        this.updateStatus('IncomingCall', data);
                        break;
                    case 'out':
                        console.log('OutgoingCall event', event);
                        break;
                    case 'ans':
                        console.log('AcceptedCall event', event)
                        if (this.call_outgoing && event.call_id === this.call_id) {
                            this.accepted(event.jsep)
                            .then(() => {
                                this.updateStatus('Accepted');
                                console.info(`[${this.call_id}] Accepted`);
                                if (this.outgoing_duration && this.outgoing_duration > c_call_infinity) {
                                    const timeout = this.getRandomMillisecond(this.outgoing_duration, this.outgoing_deviation);
                                    console.info(`[${this.call_id}] Set outgoing call timeout to: ${timeout}`);
                                    this.call_timer = setTimeout(() => {
                                        this.hangup();
                                    }, timeout);
                                }
                                let interval = 0;
                                if (delay_before_pcGetStat > this.stats_interval * 1000) {
                                    interval = delay_before_pcGetStat - (this.stats_interval * 1000);
                                }

                                setTimeout(() => { 
                                    if (this.pc !== null && this.id !== undefined) {
                                        this.pc.initGetStats(this.id, interval);
                                    } 
                                }, interval);
                            })
                        } else if(!this.call_outgoing) {
                            this.updateStatus('Accepted');
                        }
                        break;
                    case 'hangup':
                        console.log('HangupCall event', event);
                        if (event.call_id === this.call_id || !this.call_id) {
                            this.callHangup();
                            this.updateStatus('Disconnected', event.code ?? event.reason);
                            this.pc?.close();
                            this.pc = null;
                        }
                        this.call_id = null;
                        break;
                    case 'session':
                        console.log('Session event', event);
                        this.call_enable = event.event === 'registered';
                        break;
                    case 'line':
                        console.log('Line event', event);
                        this.call_outgoing = null;
                        break;
                    case 'notify':
                        console.log('Notify event', event);
                        // handleNotifyEvent(event)
                        break;
                    case 'change':
                        console.log('ChangeCall event', event);
                        // handleChangeCall(event)
                        this.updateStatus('Changed');
                        break;
                    default:
                        console.warn('Unhandled signaling event:', event);
                        break;
                }
            },
            //has in demo - just console.log
            errorCallback: (error: any) => {
                console.log(`>> errorCallback: ${error}`);
                this.callHangup();
                this.updateStatus('Disconnected', 4000);
            },
            //has in demo - just console.log
            disconnectedCallback: (reason: any, code: any) => {
                console.log(`>> disconnectedCallback: with code: ${code} and reason: ${reason}`);
                this.callHangup();
                this.updateStatus('Disconnected', code);
            },
            connectedCallback: (event: any) => {
                console.log(`>> connectedCallback: ${event}`);
            },
            //new added here
            handshakeCallback: (event: any) => {
                console.log('Handshake callback', event)
                if (event.registration !== undefined) {
                    console.log('Registration Status: ', event.registration.status)
                    if (event.registration.code) {
                        const msg = event.registration.reason || 'Unknown'
                        console.log('Registration With Error: ', `${event.registration.code} - ${msg}`)
                    }
                }
            }
        });
    }

    generateRamdomString(length: number) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    async connect() {
        console.log(`CallbuttonController: connect`);
        this.webtritSignalingClient.connect({
            url,
            token: this.token,
        });
    }

    disconnect() {
        console.log('CallbuttonController: disconnect');
        this.webtritSignalingClient?.disconnect();
    }
    
    setStream(stream: any, isRemote: boolean) {
        if (isRemote) {
            console.log('Try set Remote stream', stream);
            const remoteView = document.getElementById('remoteStream');
            if(remoteView) {
                //@ts-ignore
                remoteView.srcObject = stream;
            }
            console.log('Set Remote stream', stream);
        } else {
            console.log('Try set Local stream', stream)
            const localView = document.getElementById('localStream');
            if(localView) {
                //@ts-ignore
                localView.srcObject = stream;
            }
            console.log('Set Local stream', stream);
        }
    }

    async initPeerConnection() {
        return new PeerConnection({
            iceCandidateCallback: (candidate) => {
                if(!this.call_id || !candidate) {
                    return;
                }
                if(this.webtritSignalingClient?.ws?.readyState === 1) {
                    this.webtritSignalingClient?.execute('ice_trickle', {
                        line: this.line,
                        candidate,
                    }).then(() => {
                        console.info('Sent candidate:', candidate);
                    })
                    .catch((e: any) => console.error('Send candidate:', e));
                }
            },
            negotiationNeededCallback: (event) => {
                console.log('NegotiationNeededCallback:', event);
            },
            addStreamCallback: (stream, isRemote) => {
                this.setStream(stream, isRemote || false);
            },
            errorCallback: (error) => {
                console.log('Error callback:', error);
                this.webtritSignalingClient?.errorCallback(error);
            },
        });
    }
    
    async call() {
        try {

            if(this.call_id) {
                return false;
            }

            if(this.pc) {
                this.pc.close();
                this.pc = null;
                await new Promise(resolve => setTimeout(resolve, 1000)); // 1-second delay
            }

            this.call_outgoing = true;
            this.call_id = this.webtritSignalingClient?.generateCallId();

            this.pc = await this.initPeerConnection();

            if(!this.pc) return false;
            await this.pc.create();

            if(!this.pc) return false;
            await this.pc.setLocalStreams({
                ...constraints,
                volume: this.volume
            });

            if(!this.pc) return false;
            await this.pc.createOffer();
            
            if(!this.pc) return false;
            const sdp = this.pc.getLocalDescription();

            if(!this.pc) return false;
            if (this.pc.isNoErrors) {
                await this.webtritSignalingClient?.execute('outgoing_call', {
                    line: this.line,
                    call_id: this.call_id,
                    number: this.numberToCall,
                    jsep: sdp,
                });
            }

            return true;
        } catch (e: any) {
            console.error('Exception in call method:', e);
            this.updateStatus('Disconnected', e?.code);
        }
        
        return false;
    }

    async answer(call: IncomingCallData) {
        try {
            this.pc = await this.initPeerConnection();
            await this.pc.create();

            await this.pc.setLocalStreams(constraints)
            if (call.sdp) {
                await this.pc.setRemoteDescription(call.sdp);
                await this.pc.createAnswer();
            } else if(this.pc) {
                await this.pc.createOffer()
            }
            const jsep = this.pc?.getLocalDescription()
            if (this.pc?.isNoErrors) {
                await this.webtritSignalingClient?.execute('accept', {
                    line: call.line,
                    call_id: call.call_id,
                    jsep
                });
                this.call_id = call.call_id;
                this.line = call.line;
                this.call_outgoing = false;
            }
        } catch (e) {
            console.error('Exception in answer method:', e);
        }
    }
    
    async start_outgoing_call(_numberToCall: string) {
        if(this.call_id) {
            return false;
        }
        if(_numberToCall) {
            this.numberToCall = _numberToCall;
        }
        this.updateStatus('Start');
        console.log(`CallbuttonController: start_outgoing_call`);
        this.muteAudio(false);
        if (this.call_enable && this.outgoing_duration > c_call_disable) {
            if (this.call_id === null) {
                // No active incoming or outgoing call start outgoing
                console.info('Start a call');
                this.call_enable = false;
                const started = await this.call();
                this.call_enable = true;
                return started;
            }
        }
        return false;
    }

    wtClearTimeout() {
        if (this.call_timer) {
            clearTimeout(this.call_timer);
            this.call_timer = null;
        }
    }

    async callHangup() {
        if(this.call_id) {
            this.wtClearTimeout();
            this.call_id = null;
            this.pc?.close();
            this.pc = null;
            this.setStream(null, true);
            this.setStream(null, false);
            return true;
        } else {
            return false;
        }
    };
    
    async accepted(sdp: any) {
        try {
            if (sdp) {
                await this.pc.setRemoteDescription(sdp);
            }
        } catch (e) {
            console.error('Exception in accepted method:', e);
        }
    }

    async hangup() {
        if(this.call_id === null) {
            return false;
        }
        console.log('===============================================');
        const call_id_before = this.call_id;
        this.call_id = null;
        await this.webtritSignalingClient?.execute('hangup', {
            line: this.line,
            call_id: call_id_before,
        });
        await this.callHangup();
        this.updateStatus('Disconnected', 200);
        return true;
    }
    
    async declineIncoming(incoming: IncomingCallData) {
        if(this.statusSpan !== 'IncomingCall') {
            return;
        }
        try {
            await this.webtritSignalingClient?.execute('decline', {
                line: incoming.line,
                call_id: incoming.call_id,
            });
            await this.callHangup();
            this.updateStatus('Disconnected', 603);
        }
        catch(err) {
            console.error('Exception in declineIncoming method:', err);
        }
    }

    updateStatus(status: string, additionalInfo: string | IncomingCallData | number | null = null) {
        this.statusSpan = status;
        if(status === 'IncomingCall') {
            const message: MessageFromWidget = {
                type: 'INCOMING_CALL_TRIGGER',
                data: additionalInfo as IncomingCallData
            };
            window.postMessage(message, "*");
        }
        if(this.statusCallback) {
            this.statusCallback(this, status, additionalInfo);
        }
    }
    
    getRandomMillisecond(duration: number, deviation: number) {
        const min = Math.ceil(duration - deviation)
        const max = Math.floor(duration + deviation)
        return Math.floor(Math.random() * (max - min + 1) + min) * 1000
    }
    
    sendDtmf(tones: string) {
        try {
            this.pc?.sendDtmf(tones);
        }
        catch(err) {
            console.error('Exception in sendDtmf method:', err);
        }
    }

    async setVolume(_value: number) {
        try
        {
            this.volume = _value;
            await this.pc.setLocalStreams({
                ...constraints,
                volume: _value,
            });
        }
        catch(err) {
            console.error('Exception in setVolume method:', err);
        }
    }
    
    muteAudio(mute: boolean) {
        this.pc?.muteAudio(mute);
    }
    
    hold(hold: boolean) {
        if(hold)
        {
            this.webtritSignalingClient?.execute('hold', {
                line: this.line,
                call_id: this.call_id,
                direction: 'inactive'
            }).then(() => {
                this.updateStatus('Hold on');
            });
        }
        else
        {
            this.webtritSignalingClient?.execute('unhold', {
                line: this.line,
                call_id: this.call_id
            }).then(() => {
                this.updateStatus('Hold off');
            });
        }
    }

    muteRemote(mute: boolean) {
        this.pc?.muteRemote(mute);
    }
}