import './App.css';
import { useCallback, useMemo } from 'react';
import {Provider, useSelector} from 'react-redux';
import {store} from './store';
import { Routes } from './routes/routes';
import {
    createBrowserRouter,
    Outlet,
    RouterProvider
} from 'react-router-dom';
import SignIn from './views/SignIn';
import './services/i18n';
import './shared.css';
import Settings from './views/Settings/Settings';
import Contacts from './views/Contacts/Contacts';
import RecentCalls from './views/RecentCalls/RecentCalls';
import Keypad from './views/Keypad/Keypad';
import { ReduxState } from './store/types/store';
import Sidebar from './views/Sidebar/Sidebar';
import Config from './config.json';
import MessagesFromWidgetProcessor from './MessagesFromWidgetProcessor';
import usePostMessageToWidget from './hooks/usePostMessageToWidget';

export const RoutesAutorized = [
    {
        path: Routes.RecentCalls,
        element: (<RecentCalls></RecentCalls>),
    },
    {
        path: Routes.Contacts,
        element: (<Contacts></Contacts>),
    },
    {
        path: Routes.Keypad,
        element: (<Keypad></Keypad>),
    },
    {
        path: Routes.Settings,
        element: (<Settings></Settings>),
    }
];

const RoutesNotAuthorized = [
    {
        path: '*',
        element: (<SignIn></SignIn>),
    }
];

const App = () => {
    const {access_token} = useSelector(
        (state: ReduxState) => state.auth,
    );

    const router = useMemo(() => {
        const isLoggedIn = (access_token?.length ?? 0) > 0;
        return isLoggedIn
            ? createBrowserRouter([{
                    path: Config.BASE_PATH,
                    element: <Sidebar><Outlet /></Sidebar>,
                    //errorElement: <ErrorPage />,
                    children: RoutesAutorized,
                }])
            : createBrowserRouter(RoutesNotAuthorized);
    
    }, [access_token]);

    const handleClick = useCallback(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePostMessageToWidget({
            type: "CLICK_INSIDE_WIDGET",
            data: {}
        });
    }, []);
    
    return (
        <Provider store={store}>
            <div className='root-container' onClick={handleClick}>
                <RouterProvider router={router} />
                <MessagesFromWidgetProcessor />
            </div>
        </Provider>
    );
};

export default App;
