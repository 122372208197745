import { Colors } from "../../../styles/Colors";
import { makeStyles } from "tss-react/mui";

export type DialpadProps = {
    clicked: (ch: string) => void;
    replaceLastChar: (ch: string) => void;
    allowAD?: boolean;
    keyUp: () => void;
};

export const useStyles = makeStyles()(() => {
    return {
        container: {
            background: Colors.White,
            width: 152,
            height: 208,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        numbersRow: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    };
});
