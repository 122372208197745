import { Contact } from "../store/actions/contacts/payload";
import { User } from "../store/actions/payloads";

export function getAlis(user: User | Contact) {
    const d1 = user?.alias_name?.trim() ?? undefined;
    const d2 = ((user?.first_name?.trim()?.length
        ? user?.first_name?.trim()
        : ''
    ) + ' ' + (user?.last_name?.trim()?.length 
        ? user?.last_name?.trim()
        : ''))?.trim();
    const d3 = user?.numbers?.main;
    if(d1?.length) {
        return d1;
    }
    else if(d2?.length) {
        return d2;
    }
    else if(d3?.length) {
        return d3;
    }
    return undefined;
}

export const getInitials = (name: string | undefined) => {
    const w1 = name?.split(/[\s]+/).filter(e => (e?.length ?? 0) > 0) ?? [];
    const words = w1.length >= 2
        ? w1 
        : name?.split(/[\s-_,\t\r\n]+/).filter(e => (e?.length ?? 0) > 0) ?? [];
    let result = '';
    for(const word of words) {
        if((word?.length ?? 0) > 0) {
            result += word[0].toLocaleUpperCase();
        }
    }
    if(result.length === 0) {
        return 'U';
    }
    else if(result.length > 2) {
        result = result.substring(0, 2);
    }
    return result;
}

export function getAliasForEntity(user: User | Contact) {
    const d1 = ((user?.first_name?.trim()?.length
        ? user?.first_name?.trim()
        : ''
    ) + ' ' + (user?.last_name?.trim()?.length 
        ? user?.last_name?.trim()
        : ''))?.trim();
    const d2 = user?.alias_name?.trim() ?? undefined;
    const d3 = user?.numbers?.main;
    if(d2?.length) {
        return getInitials(d2);
    }
    else if(d1?.length) {
        const fname = user?.first_name?.trim()?.length
            ? user?.first_name?.trim()
            : '';
        const lastName = (user?.last_name?.trim()?.length 
            ? user?.last_name?.trim()
            : '');
        if(fname?.length && lastName?.length) {
            const wordsFName = fname?.split(/[\s-_,\t\r\n]+/).filter(e => (e?.length ?? 0) > 0) ?? [];
            const wordsLName = lastName?.split(/[\s-_,\t\r\n]+/).filter(e => (e?.length ?? 0) > 0) ?? [];
            return getInitials(wordsFName[0] + ' ' + wordsLName[0]);
        } else if(fname?.length) {
            return getInitials(fname);
        }
        return getInitials(lastName);
    }
    else if(d3?.length) {
        return getInitials(d3);
    }
    return undefined;
}