import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import './Signin.css';
import CustomizedButton from '../components/CustomizedButton/CustomizedButton';
import CustomizedTextField from '../components/CustomizedTextField/CustomizedTextField';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { actions } from '../store';
import { useFormik } from 'formik';
import { SignInForm, initLoginFormData, validationSchema } from './SignIn.utils';

const SignIn = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isStep2, setIsStep2] = useState(false);

    const signIn = useCallback((form: SignInForm) => {
        dispatch(actions.logIn.request(form));
    }, [dispatch]);
    
    const
    {
        values,
        submitForm,
        handleChange,
        setFieldError,
        errors
    } = useFormik<SignInForm>({
        initialValues: initLoginFormData,
        validateOnChange: false,
        onSubmit: (values) => {
            signIn(values);
        },
        enableReinitialize: false,
        validationSchema: validationSchema,
    });
    
    return (<div className='signin-root'>
        {!isStep2 ? (
            <div className='page-container'>
                <CustomizedButton
                    label={t('screens:signin.signIn')}
                    className='singin-button'
                    onClick={() => {
                        setIsStep2(true);
                    }}
                />
            </div>
        ) : (<div className='page-container'>
                <form onSubmit={submitForm}  className='form-container' autoComplete="off">
                    <CustomizedTextField
                        id={'login'}
                        value={values.login}
                        label={t('screens:signin.login')}
                        maxLength={128}
                        className='customizedTextField'
                        onChange={handleChange}
                        setFieldError={setFieldError}
                        dataQa="signin-login"
                        helperText={errors?.login}
                        disableCounter
                        autoComplete={'username'}
                    />
                    <CustomizedTextField
                        id={'password'}
                        value={values.password}
                        type={'password'}
                        label={t('screens:signin.password')}
                        maxLength={32}
                        className='customizedTextField'
                        onChange={handleChange}
                        setFieldError={setFieldError}
                        dataQa="signin-password"
                        helperText={errors?.password}
                        disableCounter
                        autoComplete={'current-password'}
                    />
                    <CustomizedButton
                        label={t('screens:signin.log_In')}
                        className={classNames('singin-button', 'step-2')}
                        onClick={() => {
                            submitForm();
                        }}
                    />
                </form>
            </div>)}
    </div>)
};

export default SignIn;
