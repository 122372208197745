import { makeStyles } from "tss-react/mui";
import { Colors } from "../../styles/Colors";
import { Call } from "../../store/actions/recentCalls/payload";
import moment from "moment";
import 'moment-timezone';
import { TFunction } from "i18next";

export const getAlias = (call: Call) => {
    const name = call.direction === "incoming" || call.direction === "forwarded"
        ? call.caller
        : call.callee;
    const regex = /(.*)\((.*)\)/gmi;
    if(name?.match(regex)) {
        const matches = regex.exec(name);
        if(matches) {
            return matches[2].trim();
        }
    }
    return name ?? '';
}

export const getNumber = (call: Call) => {
    const name = call.direction === "incoming" || call.direction === "forwarded"
        ? call.caller
        : call.callee;
    const regex = /(.*)\((.*)\)/gmi;
    if(name?.match(regex)) {
        const matches = regex.exec(name);
        if(matches) {
            return matches[1].trim();
        }
    }
    return name ?? '';
}

export const convertUtcToUserLocalDate = (connect_time_utc: string | undefined, target_time_zone: string | undefined, t: TFunction) => {
    if(!connect_time_utc?.length) {
        return '';
    }
    if(!target_time_zone?.length) {
        target_time_zone = 'Etc/UTC';
    }
    const dt = moment(connect_time_utc);
    const nowInTargetZone = moment().tz(target_time_zone);
    const yesterdayInTargetZone = moment().add('days', -1).tz(target_time_zone);
    const convertedDate = dt.tz(target_time_zone);
    if(convertedDate.date() === nowInTargetZone.date() && convertedDate.month() === nowInTargetZone.month()) {
        return t('screens:contacts.today');
    }
    else if(convertedDate.date() === yesterdayInTargetZone.date() && convertedDate.month() === yesterdayInTargetZone.month()) {
        return t('screens:contacts.yesterday');
    }
    else if(convertedDate.year() !== nowInTargetZone.year()) {
        const monthStr1 = convertedDate.format("MMM");
        const dayStr1 = convertedDate.format("DD").startsWith('0')
            ?  convertedDate.format("DD").substring(1)
            :  convertedDate.format("DD");
        return (monthStr1.length > 3 
            ?  monthStr1.substring(0, 3)
            : monthStr1)
            + ' ' + dayStr1 + ', ' + convertedDate.format("YYYY");
    }
    const monthStr = convertedDate.format("MMM");
    const dayStr = convertedDate.format("DD").startsWith('0')
        ?  convertedDate.format("DD").substring(1)
        :  convertedDate.format("DD");
    return (monthStr.length > 3 
        ?  monthStr.substring(0, 3)
        : monthStr)
        + ' ' + dayStr;
};

export const convertUtcToUserLocalTime = (ignore_seconds: boolean, connect_time_utc: string | undefined, target_time_zone: string | undefined, t: TFunction) => {
    if(!connect_time_utc?.length) {
        return '';
    }
    if(!target_time_zone?.length) {
        target_time_zone = 'Etc/UTC';
    }
    const converted = moment(connect_time_utc).tz(target_time_zone);
    return converted.format(ignore_seconds ? "HH:mm" : "HH:mm:ss");
}

export const useStyles = makeStyles()(() => {
    return {
        rootContainer: {
            width: 'calc(100% - 16px)',
            height: 'calc(100% - 56px - 8px)',
            background: Colors.White,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            padding: '8px 16px 0px 16px',
        },
        scrollDiv: {
            width: '100%',
            height: '100%',
            overflowX: 'hidden',
            overflowY: 'auto'
        },
        callCard: {
            width: '100%',
            height: 42,
            userSelect: 'none',
            padding: '7px 0px 7px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            overflow: 'hidden',
            minHeight: 42,
            position: 'relative',
        },
        scrollMoreContainer: {
            width: 'calc(100% - 32px)',
            height: 16,
            userSelect: 'none',
            padding: '0px 16px 16px 16px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row',
            overflow: 'hidden'
        },
        callCardContactInfoContainer: {
            width: 222,
            height: 42,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
        },
        callCardAvatarContainer: {
            background: 'rgba(61, 91, 104, 0.2)',
            width: 40,
            height: 40,
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'relative'
        },
        callCardAvatar: {
            borderRadius: 20,
        },
        callCardAvatarAbbreviation: {
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            padding: '10.77px 0px',
            color: 'rgba(61, 91, 104, 1)',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: 40,
            zIndex: 1
        },
        callCardDetailsContainer: {
            width: 168,
            height: 42,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: 8,
        },
        callCardContactNameContainer: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '18.76px',
            textAlign: 'left',
            color: 'rgba(44, 40, 37, 1)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
        callCardAvatarDiv: {
            width: 40,
            height: 40,
            zIndex: 2,
        },
        callCardContactNumberContainer: {
            fontSize: 11,
            fontWeight: 400,
            lineHeight: '15.4px',
            textAlign: 'left',
            color: 'rgba(139, 157, 164, 1)',
            display: 'flex',
            flexDirection: 'row',
        },
        callCardActionButtonsContainer: {
            padding: 0,
            height: 42,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 64,
            position: 'relative',
            visibility: 'hidden'
        },
        showButtons: {
            visibility: 'visible',
            '& *': {
                visibility: 'visible',
            },
            '& > *': {
                visibility: 'visible',
            }
        },
        hideButtons: {
            visibility: 'hidden',
            '& *': {
                visibility: 'hidden',
            },
            '& > *': {
                visibility: 'hidden',
            }
        },
        infoButton: {
            width: 32,
            height: 32,
            cursor: 'pointer',
            marginTop: 1,

            '& img': {
                width: 32,
                height: 32,
            },

            "& img.callButton": {
                width: 28,
                height: 28,
                marginTop: 2,
            }
        },
        dateContainer: {
            position: 'absolute',
            right: 6,
            top: 18,
            textAlign: 'right',
            width: 'fit-content',
            fontSize: 13,
            fontWeight: 400,
            lineHeight: '15.4px',
            color: 'rgba(76, 134, 183, 1)',
        },
        diplayItem: {
            display: 'flex !important',
            '& *': {
                display: 'flex !important',
            },
            '& > *': {
                display: 'flex !important',
            }
        },
        callStatusImage: {
            width: 16,
            height: 16,
            marginRight: 4,
        },
        missingIncomingCall: {
            color: 'rgba(231, 76, 60, 1) !important',
        },
        callCardDetails: {
            background: Colors.White,
            height: 'fit-content',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            position: 'relative',
        },
        callCardDetailsRow: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            display: 'none',
            fontSize: 12,
            fontWeight: 400,
            lineHeight: '18.76px',
            textAlign: 'left',
            color: 'rgba(44, 40, 37, 1)',
        },
        callCardDetailsRowTop: {
            fontSize: 14,
        },
        closeDetailsButtonContainer: {
            position: 'absolute',
            top: -6,
            right: 6,
            display: 'none'
        },
        closeDetailsButton: {
            height: 18,
            color: 'rgba(61, 91, 104, 0.64)',
            cursor: 'pointer'
        }
    };
});