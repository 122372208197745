import {ActionType, createReducer} from 'typesafe-actions';
import { actions } from "../..";
import usePostMessageToWidget from '../../../hooks/usePostMessageToWidget';

export interface SettingsReducerStateType {
    defaultCountry: string | null;
}

const getInitSettingsState = function() {
    return {
        defaultCountry: null,
    } as SettingsReducerStateType;
}

export const initialState: SettingsReducerStateType = getInitSettingsState();

export type SettingsActionsType = ActionType<typeof actions>;

const SettingsReducer = createReducer<SettingsReducerStateType, SettingsActionsType>(
    initialState,
)
    .handleAction(actions.getDefaultCountry.success, (state, action) => {
        usePostMessageToWidget({
            type: "WIDGET_CONFIG_COUNTRY",
            data: {
                value: action.payload.value,
            }
        });
        return {
            ...state,
            defaultCountry: action.payload.value,
        };
    })
    .handleAction(actions.setDefaultCountry.success, (state, action) => {
        usePostMessageToWidget({
            type: "WIDGET_CONFIG_COUNTRY",
            data: {
                value: action.payload.value,
            }
        });
        return {
            ...state,
            defaultCountry: action.payload.value,
        };
    })
    .handleAction(actions.setDefaultCountryInitially.success, (state, action) => {
        return {
            ...state,
            defaultCountry: action.payload.value,
        };
    })
;

export default SettingsReducer;