import {ActionType, createReducer} from 'typesafe-actions';
import * as actions from '../../actions/keypad';

export interface KeypadReducerStateType {
    mute: boolean;
    isCalling: boolean;
    callingOutgoingNumber?: string,
}

const getInitKeypadState = function() {
    return {
        mute: false,
        isCalling: false,
    } as KeypadReducerStateType;
}

export const initialState: KeypadReducerStateType = getInitKeypadState();

export type KeypadActionsType = ActionType<typeof actions>;

const keypadReducer = createReducer<KeypadReducerStateType, KeypadActionsType>(
    initialState,
)
    .handleAction(actions.setMuteStatus.request, (state, action) => {
        return {
            ...state,
            mute: action.payload.mute,
        };
    })
    .handleAction(actions.setIsCalling.request, (state, action) => {
        return {
            ...state,
            isCalling: action.payload.isCalling,
            callingOutgoingNumber: action.payload.number
        };
    })
;

export default keypadReducer;