import { createAsyncAction } from 'typesafe-actions';
import { APIError } from '../../types/errors';
import { GetDefaultCountryPayload } from './payload';

export const getDefaultCountry = createAsyncAction(
    'GET_DEFAULT_DIALING_COUNTRY',
    'GET_DEFAULT_DIALING_COUNTRY_SUCCESSES',
    'GET_DEFAULT_DIALING_COUNTRY_FAILED',
)<undefined, GetDefaultCountryPayload, APIError>();

export const setDefaultCountry = createAsyncAction(
    'SET_DEFAULT_DIALING_COUNTRY',
    'SET_DEFAULT_DIALING_COUNTRY_SUCCESSES',
    'SET_DEFAULT_DIALING_COUNTRY_FAILED',
)<GetDefaultCountryPayload, GetDefaultCountryPayload, APIError>();

export const setDefaultCountryInitially = createAsyncAction(
    'SET_DEFAULT_DIALING_COUNTRY_INITIALLY',
    'SET_DEFAULT_DIALING_COUNTRY_INITIALLY_SUCCESSES',
    'SET_DEFAULT_DIALING_COUNTRY_INITIALLY_FAILED',
)<GetDefaultCountryPayload, GetDefaultCountryPayload, APIError>();