import { makeStyles } from "tss-react/mui";
import { Colors } from "../../styles/Colors";

export const useStyles = makeStyles()(() => {
    return {
        textField: {
            '& .MuiInputBase-root': {
                height: 48,
                backgroundColor: Colors.White,
                borderRadius: '4px',

                '&:before': {
                    borderBottom: `1px solid ${Colors.Border}`,
                },
            },
            '& label + .MuiInput-formControl': {
                marginTop: 0,
            },
            '& .MuiInputBase-input': {
                height: 48,
                color: Colors.Text,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
                whiteSpace: 'nowrap',
                fontWeight: 400,
                fontSize: 15,
                lineHeight: '19px',
            },
            '& .Mui-focused': {
                color: `${Colors.Primary} !important`,
                marginLeft: 0,

                '& .character-counter': {
                    visibility: 'visible',
                },
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: Colors.Primary,
                },
            },
            '& .Mui-disabled:hover:before': {
                borderBottomColor: Colors.Border,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: Colors.Primary,
            },
            '& .MuiInputBase-input.Mui-disabled': {
                opacity: 1,
                color: Colors.LightGraySecondary2
            },
            '& .MuiInputLabel-root': {
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            },
            '& .MuiInputLabel-asterisk': {
                color: Colors.Error,
            },
        },
        counter: {
            position: 'absolute',
            top: 59,
            right: 10,
            visibility: 'hidden',
            color: Colors.Gray9,
        },
        counterReached: {
            color: `${Colors.Error} !important`,
            visibility: 'visible',
        },
        icon: {
            '& .MuiInputLabel-root': {
                marginLeft: 32,
            },
            '& .MuiFormLabel-filled': {
                marginLeft: 0,
            },
        },
        error: {
            '& .MuiInputLabel-root': {
                color: Colors.PlaceHolder,
            },
            '& .MuiFormLabel-filled, .MuiFormHelperText-root': {
                color: Colors.Error,
            },
            '& .MuiFormHelperText-root': {
                width: '100%',
            },
            '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                    borderColor: Colors.Error,
                },
            },
            '& fieldset': {
                borderColor: `${Colors.Error}!important`,
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: Colors.Error,
            },
        },
        helperText: {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 12,
            width: 'unset',
            marginBottom: '-25px',
            lineHeight: '12px'
        },
        helperTextWithCounter: {
            marginRight: 45,
        },
        readOnlyContainer: {
            backgroundColor: 'transparent !important',

            '& .MuiInputBase-root': {
                backgroundColor: 'transparent !important',
            },

            '& .MuiInputBase-input.Mui-disabled': {
                opacity: 1,
                color: Colors.Text
            },
            '& .MuiFormLabel-root.Mui-disabled': {
                color: Colors.PlaceHolder,
            },
            '& .Mui-disabled': {
                '& .MuiInputAdornment-root': {
                    '& .MuiSvgIcon-root': {
                        fill: Colors.Gray9,
                    },
                },
                '& .MuiIconButton-label': {
                    '& .MuiSvgIcon-root': {
                        fill: Colors.Gray9,
                    },
                },
            }
        },
        disabledContainer: {
            '& .Mui-disabled': {
                '&:before': {
                    borderBottom: `1px dashed ${Colors.LightGraySecondary} `,
                },
                '& .MuiInputAdornment-root': {
                    '& .MuiSvgIcon-root': {
                        opacity: 0.8,
                    },
                },
            },
        }
    };
});