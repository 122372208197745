import React, {useCallback} from 'react';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { InputAdornment } from '@mui/material';
import { useStyles } from './CustomizedTextField.utils';

export type CustomTextFieldProps ={
    id?: string;
    value?: string | number;
    label?: string;
    className?: string;
    onClick?: () => void;
    type?: string;
    required?: boolean;
    onChange?: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => void;
    setFieldError?: (field: string, value: string | undefined) => void;
    handleSubmit?: (e: React.ChangeEvent<any>) => void;
    disabled?: boolean;
    readOnly?: boolean;
    helperText?: string;
    dataQa?: string;
    placeholder?: string;
    iconPosition?: 'end' | 'start';
    icon?: React.ReactNode;
    maxLength?: number;
    disableCounter?: boolean;
    autoComplete?: string;
};

const CustomizedTextField: React.FC<CustomTextFieldProps> = ({
    id,
    value,
    label,
    className,
    onClick,
    type,
    required,
    onChange,
    setFieldError,
    handleSubmit,
    disabled,
    readOnly,
    helperText,
    dataQa,
    placeholder,
    iconPosition,
    icon,
    maxLength,
    disableCounter = false,
    autoComplete
}) => {
    
    const inputRef = React.useRef<any>(null);
    const { classes } = useStyles();

    const onChangeText = useCallback(
        (event: any) => {
            const {selectionStart, selectionEnd} = event.target;

            if (type === 'number') {
                if (event.target.value === '' && required) {
                    event.target.value = '0';
                }

                if (event.target.validity.valid) onChange?.(event);
            } else {
                onChange?.(event);
            }
            !!id && setFieldError?.(id, undefined);

            if (
                inputRef?.current &&
                selectionStart &&
                selectionEnd
            ) {
                setTimeout(() => {
                    inputRef.current?.setSelectionRange(
                        selectionStart,
                        selectionEnd,
                    );
                }, 0);
            }
        },
        [id, setFieldError, type, onChange, required],
    );

    const onKeyPress = useCallback(
        (ev: React.KeyboardEvent<any>) => {
            if (ev?.key === 'Enter') {
                if (handleSubmit) {
                    handleSubmit(ev);
                    ev.preventDefault();
                }
            }
        },
        [handleSubmit],
    );

    return (
        <TextField
            autoComplete={autoComplete || 'off'}
            inputRef={inputRef}
            id={id}
            value={value}
            className={classNames(
                classes.textField,
                {
                    [classes.icon]: icon,
                    [classes.error]: helperText,
                },
                className,
                readOnly && classes.readOnlyContainer,
                disabled && classes.disabledContainer
            )}
            onChange={onChangeText}
            onKeyPress={onKeyPress}
            onClick={() => {
                if (onClick && (readOnly || disabled)) {
                    onClick();
                }
            }}
            disabled={
                disabled || readOnly
            }
            label={label}
            error={!!helperText}
            required={required}
            type={type}
            data-qa={dataQa}
            placeholder={placeholder}
            InputProps={{
                [iconPosition === 'start'
                    ? 'startAdornment'
                    : 'endAdornment']: !!icon && (
                    <InputAdornment position="start">
                        {icon}
                    </InputAdornment>
                ),
            }}
            inputProps={{
                maxLength,
                max: maxLength,
            }}
            helperText={
                <>
                    {helperText && (
                        <span
                            className={classNames(
                                classes.helperText,
                                maxLength &&
                                !disableCounter &&
                                classes.helperTextWithCounter,
                            )}
                            data-qa={`helper-${dataQa}`}
                            data-testid="textfield-helper"
                        >
                        {helperText}
                    </span>
                    )}

                    {maxLength && !disableCounter && (
                        <span
                            className={classNames(
                                'character-counter',
                                classes.counter,
                                (value?.toString().length || 0) >
                                maxLength && classes.counterReached,
                            )}
                        >
                        {value?.toString().length || 0}
                            /
                            {maxLength}
                    </span>
                    )}
                </>
            }
        />
    );
};

export default CustomizedTextField;