import {put, takeLatest} from 'redux-saga/effects';
import * as actions from '../../actions/recentCalls';
import { AxiosResponse } from 'axios';
import { api } from '../../services/axios';
import { EndPoints } from '../../endpoints';
import { ActionType } from 'typesafe-actions';
import { Call, GetCallsResponse } from '../../actions/recentCalls/payload';

export function* getCalls(action: ActionType<typeof actions.getCalls.request>) {
    try {
        const res: AxiosResponse<{
            items: Call[],
            pagination: {
                items_per_page: number,
                items_total: number, 
                page: number
            }
        }> = yield api.get(EndPoints.RecentCalls, {
            params: {
                page: action.payload.page,
                items_per_page: action.payload.itemsPerPage,
            }
        });
        const items: Call[] = res.data?.items ?? [];
        const items_total: number = res.data?.pagination?.items_total ?? 0;
        yield put(
            actions.getCalls.success({
                ...action.payload,
                items: items,
                items_total: items_total
            } as GetCallsResponse),
        );
    } catch (err) {
        // @ts-ignore
        yield put(actions.getCalls.failure(err));
    }
}

export const callsSaga = [
    takeLatest(actions.getCalls.request, getCalls),
];
