import {ActionType, createReducer} from 'typesafe-actions';
import * as actions from '../../actions/contacts';
import { Contact } from '../../actions/contacts/payload';
import { APIError } from '../../types/errors';
import usePostMessageToWidget from '../../../hooks/usePostMessageToWidget';

export interface ContactsReducerStateType {
    contacts: Contact[] | null;
    isLoadingContacts: boolean;
    loadingError?: APIError | null
}

const getInitContactsState = function() {
    return {
        contacts: null,
        isLoadingContacts: false,
        loadingError: null
    } as ContactsReducerStateType;
}

export const initialState: ContactsReducerStateType = getInitContactsState();

export type ContactsActionsType = ActionType<typeof actions>;

const ContactsReducer = createReducer<ContactsReducerStateType, ContactsActionsType>(
    initialState,
)
    .handleAction(actions.getContacts.request, (state, action) => {
        return {
            ...state,
            isLoadingContacts: true,
            loadingError: null
        };
    })
    .handleAction(actions.getContacts.success, (state, action) => {
        usePostMessageToWidget({
            type: "FETCH_CONTACT_LIST",
            data: action.payload
        });
        return {
            ...state,
            contacts: action.payload,
            isLoadingContacts: false,
            loadingError: null
        };
    })
    .handleAction(actions.getContacts.failure, (state, action) => {
        return {
            ...state,
            isLoadingContacts: false,
            loadingError: action.payload
        };
    })
;

export default ContactsReducer;