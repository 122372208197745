import Button from '@mui/material/Button';
import classNames from 'classnames';
import './CustomizedButton.css';

export type CustomizedButtonProps = 
{
    label: string,
    onClick?: () => void,
    className?: string,
    variant?: 'text' | 'contained' | 'outlined'
};

const CustomizedButton: React.VFC<CustomizedButtonProps> = ({
    label,
    onClick,
    className,
    variant = 'contained'
}) => {
    return (<Button className={classNames('customButton', className)}
        variant={variant}
        onClick={onClick}
    >
        {label}
    </Button>)
};

export default CustomizedButton;
