
export enum Routes {
    SignIn = 'sign-in',
    Contacts = 'contacts',
    RecentCalls = 'recent',
    Keypad = 'keypad',
    Settings = 'settings',
}

export enum PublicRoutes {
    SignIn = Routes.SignIn
}

export const getRoute = (route: string, options: object) => {
    let output = route;

    for (const [key, value] of Object.entries(options)) {
        const search = `:${key}`;

        // @ts-ignore
        output = output.replace(search, value);
    }

    return output;
};
