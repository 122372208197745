import { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

export type PadkeyProps = {
    icon: ReactNode,
    label: string,
    onClick: () => void,
    disabled: boolean
};

export const useStyles = makeStyles()(() => {
    return {
        buttonContainer: {
            width: 'fit-content',
            minWidth: 40,
            height: 64,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: 12,
            marginRight: 12,
        },
        iconContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row'
        },
        keymain: {
            width: 40,
            height: 40,
            background: 'rgba(197, 206, 210, 0.54)',
            display: 'flex',
            cursor: 'pointer',
            userSelect: 'none',
            borderRadius: 20,
            flexDirection: 'row',
            justifyContent: 'center',
            
            '& svg': {
                width: 24,
                height: 24,
                '& path': {
                    fill: 'rgba(61, 91, 104, 1)',
                    fillOpacity: 0.8,
                },
            },
        },
        keymainDisabled: {
            background: 'rgba(197, 206, 210, 0.32)',
            cursor: 'unset',
        },
        keymainVertical: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        keymainVerticalDisabled: {
            '& svg': {
                width: 24,
                height: 24,
                '& path': {
                    fill: 'rgba(61, 91, 104, 0.4)',
                    fillOpacity: 0.8,
                },
            },
        },
        labelContainer: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '15px',
            textAlign: 'center',
            color: 'rgba(139, 157, 164, 1)',
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            marginTop: 8,  
            whiteSpace: 'nowrap',
            height: 16
        }
    };
});