import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { callDuration, initKeyPadData, useStyles, validationSchema } from './Keypad.utils';
import { useFormik } from 'formik';
import { KeypadForm } from '../../store/actions/payloads';
import CallButton from '../../assets/call_button.svg';
import CallButtonDisabled from '../../assets/call_button_disabled.svg';
import CallEndButton from '../../assets/call_end.svg';
import Inactive from '../../assets/inactive.svg';
import classNames from 'classnames';
import Dialpad from './Dialpad/Dialpad';
import Signaling, { IncomingCallData } from '../../webrtc/signaling';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types/store';
import { useTranslation } from 'react-i18next';
import PadImagekey from './Dialpad/PadImagekey/PadImagekey';
import MicOffOutlined from '@mui/icons-material/MicOffOutlined';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseIcon from '@mui/icons-material/Pause';
import DialpadIcon from '@mui/icons-material/Dialpad';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Call, MicOutlined } from '@mui/icons-material';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import usePostMessageToWidget from '../../hooks/usePostMessageToWidget';
import { RingingData, UserStatusUpdate } from '../../store/types/messagesToWidget';
import { actions } from '../../store';
import useEventListener from '../../hooks/useEventListener';
//@ts-ignore
import outgoingRingtone from '../../assets/outgoing-call-1.mp3';
//@ts-ignore
import incomingRingtone from '../../assets/incoming-call-1.mp3';
import Padkey from './Dialpad/Padkey/Padkey';

const Keypad: React.FC = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const stopCallingDisabledForConnectionTimeOut = 3000;

    const audioOutgoing = useMemo(() => { 
        const audio = new Audio(outgoingRingtone);
        audio.onended = async (e) => {
            audio.currentTime = 0;
            var isPlaying = audio.currentTime > 0 && !audio.paused && !audio.ended && audio.readyState > audio.HAVE_CURRENT_DATA;
            if (!isPlaying) {
                try {
                    await audio.play();
                } catch {}
            }
        };
        return audio;
    }, []);
    
    const audioIncoming = useMemo(() => { 
        const audio = new Audio(incomingRingtone);
        audio.onended = async (e) => {
            audio.currentTime = 0;
            await audio.play();
        };
        return audio;
    }, []);
    
    const {register, webTritSignaling, incomingCallStatus} = useSelector(
        (state: ReduxState) => state.auth,
    );
    
    const isMute = useSelector(
        (state: ReduxState) => state.keypad?.mute ?? false,
    );
    
    const isCalling = useSelector(
        (state: ReduxState) => state.keypad?.isCalling ?? false,
    );
    
    const callingOutgoingNumber = useSelector(
        (state: ReduxState) => state.keypad?.callingOutgoingNumber,
    );
    
    const contacts = useSelector(
        (state: ReduxState) => state.contacts?.contacts,
    );
    
    const [isIncomingDownloading, setisIncomingDownloading] = useState<boolean>(false);
    
    const playOutgoingAudio = useCallback(async () => {
        audioOutgoing.currentTime = 0;
        
        var isPlaying = audioOutgoing.currentTime > 0
            && !audioOutgoing.paused
            && !audioOutgoing.ended
            && audioOutgoing.readyState > audioOutgoing.HAVE_CURRENT_DATA;
        if (!isPlaying) {
            try {
                await audioOutgoing.play();
            } catch {}
        }
    }, [audioOutgoing]);

    const stopOutgoingAudio = useCallback(async () => {
        if(!audioOutgoing.paused) {
            await audioOutgoing.pause();
        }
    }, [audioOutgoing]);
    
    const playIncomingAudio = useCallback(async () => {
        if(isIncomingDownloading) {
            return;
        }
        if(audioIncoming.currentTime === 0) {
            setisIncomingDownloading(true);
            await audioIncoming.play();
            setisIncomingDownloading(false);
        }
    }, [audioIncoming, setisIncomingDownloading, isIncomingDownloading]);
    
    const stopIncomingAudio = useCallback(async () => {
        if(!audioIncoming.paused) {
            await audioIncoming.pause();
            audioIncoming.currentTime = 0;
        }
    }, [audioIncoming]);

    const [showKeyPadOnCalling, setShowKeyPadOnCalling] = React.useState<boolean>(false);
    const [isConnected, setIsConnected] = React.useState<boolean>(false);
    const [callStartTime, setCallStartTime] = React.useState<number>(0);
    const [callDurationFormatted, setCallDurationFormatted] = React.useState<string>('00:00');
    const [customError, setCustomError] = React.useState<string | null>(null);
    const [isSpeakerOff, setIsSpeakerOff] = React.useState<boolean>(false);
    const [isHold, setIsHold] = React.useState<boolean>(false);
    const [dtmfCodes, setDtmfCodes] = React.useState<string | null>(null);
    const [stopCallingDisabledForConnection, setStopCallingDisabledForConnection] = React.useState<boolean>(false);

    const setIsMute = useCallback((value: boolean) => {
        dispatch(actions.setMuteStatus.request({
            mute: value
        }));
    }, [dispatch]);

    const setIsCalling = useCallback((value: boolean) => {
        dispatch(actions.setIsCalling.request({
            isCalling: value
        }));
    }, [dispatch]);
    
    const stopCalling = useCallback(async (must: boolean = false) => {
        if(!must && stopCallingDisabledForConnection) {
            return;
        }
        setShowKeyPadOnCalling(false);
        setCallStartTime(0);
        setIsConnected(false);
        setDtmfCodes(null);
        await stopOutgoingAudio();
        await stopIncomingAudio();
        if(incomingCallStatus) {
            if(isConnected) {
                await webTritSignaling?.hangup();
            } else {
                await webTritSignaling?.declineIncoming(incomingCallStatus);
            }
            dispatch(actions.setIncomingCallStatus.request(null));
        } else {
            await webTritSignaling?.hangup();
        }
        setIsCalling(false);
    }, [setIsCalling, incomingCallStatus, dispatch, webTritSignaling, isConnected, stopOutgoingAudio, stopIncomingAudio, 
            setDtmfCodes, setShowKeyPadOnCalling, setCallStartTime, setIsConnected, stopCallingDisabledForConnection]);

    const funcStatusCallBack = useCallback((signaling: Signaling, status: string, additionalInfo: string | IncomingCallData | number | null = null) => {
        try {
            switch(status) {
                case 'Accepted':
                    setIsConnected(true);
                    stopOutgoingAudio().then(() => {
                        const dt = new Date().valueOf();
                        setCallStartTime(dt);
                        signaling.muteAudio(false);
                    });
                    break;
                case 'Disconnected':
                    if(additionalInfo)
                    {
                        const translationKey = 'errors:sipErrorCodes.' + additionalInfo;
                        const translation = t(translationKey);
                        setCustomError(translationKey === translation
                            ? additionalInfo + ''
                            : translation
                        );
                        let timeout: NodeJS.Timeout;
                        timeout = setTimeout(() => {
                            setCustomError(null);
                            clearTimeout(timeout);
                        }, 3000);
                    }
                    stopCalling(true);

                    if (additionalInfo === 4610 || additionalInfo === 4000 || additionalInfo === 1011 || additionalInfo === 490) {
                        //@ts-ignore
                        window.signaling?.callHangup();
                        //@ts-ignore
                        window.signaling?.disconnect();
                        //@ts-ignore
                        window.signaling = null;

                        dispatch(actions.updateWebSignalingState.request(null));
                        setTimeout(() => {
                            dispatch(actions.updateWebSignalingCallBackFunc.request(funcStatusCallBack));
                        }, 10);

                        const form: UserStatusUpdate = 
                        {
                            register: false,
                            doNotExecuteApi: true
                        };
                        dispatch(actions.setRegistrationStatus.request(form));
                    }
                    break;
                case 'Error':
                    if(additionalInfo === 'Permission denied')
                    {
                        additionalInfo = t('errors:keypad.device_permission_denied');
                        setCustomError(additionalInfo + '');
                    }
                    else {
                        const translationKey = 'errors:sipErrorCodes.' + additionalInfo;
                        const translation = t(translationKey);
                        setCustomError(translationKey === translation
                            ? additionalInfo + ''
                            : translation
                        );
                    }
                    let timeout: NodeJS.Timeout;
                    timeout = setTimeout(() => {
                        setCustomError(null);
                        clearTimeout(timeout);
                    }, 3000);
                    stopCalling(true);
                    break;
                case 'IncomingCall':
                    setStopCallingDisabledForConnection(false);
                    break;
            }
        }
        catch(ex)
        {
            console.error(ex);
        }
    }, [setIsConnected, stopCalling, setCallStartTime, setCustomError, t, stopOutgoingAudio, dispatch, setStopCallingDisabledForConnection]);

    useEffect(() => {
        if(webTritSignaling) {
            dispatch(actions.updateWebSignalingCallBackFunc.request(funcStatusCallBack));
        } else {
            dispatch(actions.updateWebSignalingCallBackFunc.request(null));
        }
    }, [funcStatusCallBack, dispatch, webTritSignaling]);
    
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (callStartTime > 0 && isConnected) {
            setCallDurationFormatted(callDuration(callStartTime));
            timeout = setTimeout(() => {
                setCallDurationFormatted(callDuration(callStartTime));
            }, 1000);
        }

        return () => clearTimeout(timeout);
    }, [isConnected, callDurationFormatted, callStartTime]);

    const
    {
        values,
        submitForm,
        setFieldValue
    } = useFormik<KeypadForm>({
        initialValues: initKeyPadData,
        validateOnChange: false,
        onSubmit: async (values) => {
            if(!values?.value?.length) {
                return;
            }
            if(stopCallingDisabledForConnection) {
                return;
            }
            setStopCallingDisabledForConnection(true);
            setDtmfCodes(null);
            const started = await webTritSignaling?.start_outgoing_call(values.value);
            if(started) {
                await playOutgoingAudio();
                setIsMute(false);
                setIsSpeakerOff(false);
                setIsCalling(true);
                setTimeout(() => {
                    setStopCallingDisabledForConnection(false);
                }, stopCallingDisabledForConnectionTimeOut);
            } else {
                setStopCallingDisabledForConnection(false);
            }
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
    });
    
    const contactNameInTheContactsList = useMemo(() => {
        if((incomingCallStatus?.callerName?.length ?? 0) > 0) return incomingCallStatus?.callerName;
        if(!contacts || !contacts.length || !values?.value?.length) return null;
        if((callingOutgoingNumber?.length ?? 0) > 0) {
            const contactOutgoing = contacts.find(e => e.numbers?.ext?.toUpperCase() === callingOutgoingNumber?.toUpperCase());
            return contactOutgoing?.alias_name ?? null;
        }
        const contact = contacts.find(e => e.numbers?.ext?.toUpperCase() === values?.value?.toUpperCase());
        if(!contact?.alias_name?.length) return null;
        return contact.alias_name;
    }, [callingOutgoingNumber, contacts, values, incomingCallStatus]);

    useEffect(() => {
        const data = {
            callType: incomingCallStatus ? 'incoming' : 'outgoing',
            callStatus: isCalling
                ? isConnected
                    ? 'connected'
                    : 'ringing'
                : 'disconnected',
            callTimer: isCalling && isConnected
                ? callDurationFormatted
                : null,
            customError: customError,
            phoneNumber: contactNameInTheContactsList !== null
                ? contactNameInTheContactsList
                : values.value,
            isMute: isMute
        } as RingingData;

        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePostMessageToWidget({
            type: "RINGING_CALLBACK",
            data: data
        });
    }, [isCalling, contactNameInTheContactsList, isConnected, callDurationFormatted, customError, values, isMute, incomingCallStatus]);

    useEffect(() => {
        if(isCalling && callingOutgoingNumber?.length) {
            setFieldValue('value', callingOutgoingNumber);
            setCallDurationFormatted('00:00');
            setTimeout(() => {
                submitForm().then(() => {
                });
            }, 100);
            dispatch(actions.setIsCalling.request({
                isCalling: true,
                number: undefined
            }));
        }

    }, [isCalling, callingOutgoingNumber, setFieldValue, setCallDurationFormatted, dispatch, submitForm]);

    const clicked = useCallback((ch: string) => {
        if(showKeyPadOnCalling && isConnected)
        {
            const newDtmfValue = (dtmfCodes?.length ?? 0) > 0 
                ? dtmfCodes + ch
                : ch;
            setDtmfCodes(newDtmfValue);
            return;
        }
        const newValue = values.value + ch;
        setFieldValue('value', newValue);
    }, [values, setFieldValue, showKeyPadOnCalling, isConnected, dtmfCodes, setDtmfCodes]);
    
    const keyUp = useCallback(() => {
        if(!isConnected || !showKeyPadOnCalling) {
            return;
        }
        const ch = dtmfCodes && dtmfCodes.length 
            ? dtmfCodes.substring(dtmfCodes.length - 1)
            : null;
        if(showKeyPadOnCalling && ch)
        {
            webTritSignaling?.sendDtmf(ch);
        }
    }, [showKeyPadOnCalling, webTritSignaling, dtmfCodes, isConnected]);

    useEffect(() => {
        if(!register || !isCalling) {
            stopCalling();
        }
    }, [stopCalling, register, isCalling]);

    useEffect(() => {
        webTritSignaling?.muteAudio(isMute);
    }, [isMute, webTritSignaling]);

    useEffect(() => {
        if(incomingCallStatus) {
            if(isConnected) {
                stopIncomingAudio();
            } else {
                playIncomingAudio();
            }
        }
    }, [incomingCallStatus, isConnected, stopIncomingAudio, playIncomingAudio]);

    useEffect(() => {
        if(!isCalling) {
            setFieldValue('value', '');
        }
    }, [isCalling, setFieldValue]);

    const replaceLastChar = useCallback((ch: string) => {
        const replaceInDmf = (showKeyPadOnCalling && isConnected);
        if(ch === '') {
            if(replaceInDmf) {
                if((dtmfCodes?.length ?? 0) > 0) {
                    let newValue = dtmfCodes?.substring(0, dtmfCodes.length - 1) ?? null;
                    if(!newValue?.length) newValue = null;
                    setDtmfCodes(newValue);
                }
            } else {
                if(values.value.length > 0) {
                    const newValue = values.value.substring(0, values.value.length - 1);
                    setFieldValue('value', newValue);
                }
            }
        } else {
            if(replaceInDmf) {
                const newDtmfValue = (dtmfCodes?.length ?? 0) > 0 
                    ? dtmfCodes + ch
                    : ch;
                setDtmfCodes(newDtmfValue);
            } else {
                const newValue = values.value + ch;
                setFieldValue('value', newValue);
            }
        }
    }, [values, setFieldValue, showKeyPadOnCalling, isConnected, dtmfCodes, setDtmfCodes]);

    const processPaste = useCallback((event: { ctrlKey: any; key: string; }) => {
        if (event.ctrlKey && (event.key === 'v' || event.key === 'V')) {
            try{
                navigator.clipboard
                    ?.readText()
                    ?.then((cb: string) => {
                        const newValue = cb.split(' ').join('');
                        setFieldValue('value', newValue);
                    });
            }
            catch{
            }
        }
    }, [setFieldValue]);

    const onClickSpeaker = useCallback(() => {
        const newVal = !isSpeakerOff;
        setIsSpeakerOff(newVal);
        webTritSignaling?.muteRemote(newVal);
    }, [isSpeakerOff, webTritSignaling, setIsSpeakerOff]);

    const onClickHold = useCallback(() => {
        const newVal = !isHold;
        setIsHold(newVal);
        webTritSignaling?.hold(newVal);
    }, [isHold, webTritSignaling, setIsHold]);

    useEffect(() => {
        window.removeEventListener("keydown", processPaste);
        window.addEventListener("keydown", processPaste);
    }, [processPaste]);
    
    const dynamicFontSize = useMemo(() => {
        const len = values.value?.length ?? 0;
        if(len < 17) {
            return '24px';
        } else if (len >= 24) {
            return '17px';
        } else {
            const size = 24 - ((len - 17) * 3 / 4);
            return size + 'px';
        }
    }, [values]);
    
    const cropTooLongValues = useMemo(() => {
        const len = values.value?.length ?? 0;
        if(len <= 24) {
            return values.value;
        }
        else if (len > 32) {
            const newValue = values.value.substring(0, 32);
            setFieldValue('value', newValue);
            return newValue;
        }
        else {
            const start = len - 24;
            return '...' + values.value.substring(start);
        }
    }, [values, setFieldValue]);

    const keydownHandler = useCallback(async (event: any) => {
        if((event.key >= '0' && event.key <= '9') || event.key === '+' || event.key === '*' || event.key === '#') {
            clicked(event.key);
        } else if(event.code === 'Backspace') {
            replaceLastChar('');
        } else if(event.code === 'Enter') {
            if(!register || isConnected) 
            {
                return;
            }
            if(incomingCallStatus) {
                await webTritSignaling?.answer(incomingCallStatus);
            } else {
                await submitForm();
            }
        }
    }, [clicked, replaceLastChar, register, submitForm, webTritSignaling, incomingCallStatus, isConnected]);

    useEventListener('keydown', keydownHandler);

    const displayValueInNumberLine = useMemo(() => {
        if(isCalling && showKeyPadOnCalling && dtmfCodes !== null) {
            return dtmfCodes;
        }
        else if(contactNameInTheContactsList !== null && isCalling)
        {
            return contactNameInTheContactsList;
        }
        else {
            return cropTooLongValues;
        }
    }, [contactNameInTheContactsList, isCalling, showKeyPadOnCalling, dtmfCodes, cropTooLongValues]);

    return (<div className={classes.container}>
        <div className={classes.inputLabelContainer}>
            <div className={classes.inputLabel} style={{
                fontSize: dynamicFontSize
            }} >
                {displayValueInNumberLine}
            </div>
            {
                (customError || isCalling) && (
                <div className={classNames(classes.callingStatusLabel, customError && classes.customError)}>
                    {customError ? (
                        <>
                            {customError}
                        </>
                    ) : isCalling && !customError && (
                        <>
                            {incomingCallStatus ?
                                isConnected 
                                    ? callDurationFormatted
                                    : (t('screens:keypad.incomingCall'))
                            :
                                isConnected 
                                    ? callDurationFormatted
                                    : (t('screens:keypad.calling') + '...')}
                        </>
                    )}
                </div>
            )}
        </div>
        {(!isCalling || showKeyPadOnCalling) && (
            <div className={classes.dialPadContainer}>
                <Dialpad
                    clicked={clicked}
                    replaceLastChar={replaceLastChar}
                    allowAD={showKeyPadOnCalling}
                    keyUp={keyUp}
                />
            </div>
        )}
        {!isCalling && !showKeyPadOnCalling ? (
            <div className={classes.actionButtons}>
                <div className={classNames(classes.callButton, classes.actionButton, !register && classes.disabledCallButton)} 
                onClick={async () => {
                    if(!register) 
                    {
                        return;
                    }
                    if(stopCallingDisabledForConnection) {
                        return;
                    }
                    await submitForm();
                }}>
                    {register 
                        ? (<img src={CallButton} alt="call-button" />)
                        : (<img src={CallButtonDisabled} alt="call-button" />)}
                </div>
                <div className={classNames(classes.actionButton, classes.backspaceButtonContainer)}
                    onClick={() => replaceLastChar('')}
                >
                    <Padkey chars=" "
                        clickedChar={() => {
                        }} replaceLastChar={() => {
                            setFieldValue('value', '');
                        }} keyUp={() => {
                        }} className={classes.backspaceButton}
                    >
                        <img src={Inactive} alt="backspace-button" />
                    </Padkey>
                </div>
            </div>
        ) 
        : showKeyPadOnCalling ? (<></>)
        : (<div className={classes.keysOnCalling}>
                <div className={classes.actionButtonsOnCalling}>
                    <PadImagekey
                        icon={isMute ? <MicOffOutlined /> : <MicOutlined />}
                        label={isMute ? t('screens:keypad.unmute') : t('screens:keypad.mute')}
                        onClick={() => setIsMute(!isMute)}
                        disabled={isCalling && !isConnected}
                    />
                    <PadImagekey
                        icon={isSpeakerOff ? <VolumeOffIcon /> : <VolumeUpIcon />}
                        label={isSpeakerOff ? t('screens:keypad.speakerOn') : t('screens:keypad.speakerOff')}
                        onClick={onClickSpeaker}
                        disabled={isCalling && !isConnected}
                    />
                </div>
                <div className={classes.actionButtonsOnCalling}>
                    <PadImagekey
                        icon={isHold ? <Call /> : <PauseIcon />}
                        label={isHold ? t('screens:keypad.unhold') : t('screens:keypad.hold')}
                        onClick={onClickHold}
                        disabled={isCalling && !isConnected}
                    />
                    <PadImagekey
                        icon={<DialpadIcon />}
                        label={t('screens:keypad.keypad')}
                        onClick={() => {
                            setShowKeyPadOnCalling(true);
                        }}
                        disabled={isCalling && !isConnected}
                    />
                </div>
            </div>
        )}
        {isCalling && (
            <div className={classNames(classes.actionButtonsOnCalling, 
                showKeyPadOnCalling && classes.actionButtonsOnPadKey)}>
                {showKeyPadOnCalling && (
                    <div className={classNames(classes.callButton, classes.backButton)} onClick={() => {
                        setShowKeyPadOnCalling(false);
                    }}>
                        <div className={classes.internalCallButtonContainer}>
                            <ArrowBackIcon />
                        </div>
                    </div>
                )}
                {!showKeyPadOnCalling && incomingCallStatus && !isConnected && (
                    <div className={classNames(classes.callButton, classes.callButtonAnswer)} onClick={() => {
                        if(!register) 
                            return;
                        webTritSignaling?.answer(incomingCallStatus);
                    }}>
                        <img src={CallButton} alt="call-button" />
                    </div>
                )}
                <div className={ classNames(classes.callButton,
                    stopCallingDisabledForConnection && classes.buttonDisabled
                )} onClick={async () =>  {
                    if(stopCallingDisabledForConnection) {
                        return;
                    }
                    //setFieldValue('value', '');
                    await stopCalling();
                }}>
                    <img src={CallEndButton} alt="end-call-button" />
                </div>
                {showKeyPadOnCalling && (
                    <div className={classNames(classes.callButton, classes.backButton)} onClick={() => {
                        setShowKeyPadOnCalling(false);
                    }}>
                    </div>
                )}
            </div>
        )}
    </div>);
};

export default Keypad;