import React, { useCallback, useEffect } from 'react';
import { convertUtcToUserLocalDate, convertUtcToUserLocalTime, getAlias, getNumber, useStyles } from './RecentCalls.utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { ReduxState } from '../../store/types/store';
import { actions } from '../../store';
import { Call, GetCallsPayload } from '../../store/actions/recentCalls/payload';
import CallButton from '../../assets/call_button.svg';
import ContactInfoButton from '../../assets/contacts__info_icon.svg';
import { BeatLoader } from 'react-spinners';
import IncomingAnsweredSvg from '../../assets/incoming_answered.svg';
import IncomingMissingSvg from '../../assets/incoming_missing.svg';
import OutgingMissingSvg from '../../assets/outgoing_missing.svg';
import OutgingAnsweredSvg from '../../assets/outgoing_answered.svg';
import classNames from 'classnames';
import NoData from '../../components/NoData/NoData';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { getInitials } from '../../helpers/AliasHelper';

const RecentCalls = () => {
    const { classes } = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {calls, initialLoading, isLoadingCalls, items_total } = useSelector(
        (state: ReduxState) => state.recentCalls,
    );

    const {contacts } = useSelector(
        (state: ReduxState) => state.contacts,
    );

    const {user} = useSelector(
        (state: ReduxState) => state.auth
    );

    const itemsPerPage = 25;
    
    const fetchPage = useCallback((pagesLoad: number) => {
        dispatch(actions.getCalls.request({
            page: pagesLoad + 1,
            itemsPerPage: itemsPerPage,
        } as GetCallsPayload));
    }, [dispatch]);

    const fetchMoreData = useCallback(() => {
        const alreadyHasRows = (calls?.length ?? 0);
        if(alreadyHasRows >= (items_total ?? 0)) {
            return;
        }
        const pagesLoad = (alreadyHasRows - (alreadyHasRows % itemsPerPage)) / itemsPerPage;
        fetchPage(pagesLoad);
    }, [fetchPage, calls, items_total]);

    useEffect(() => {
        fetchPage(0);
    }, [fetchPage]);
    
    const showActionButtons = useCallback((parentId: string) => {
        const parentObj = document.getElementById(parentId);
        const showElem = parentObj?.getElementsByClassName(classes.callCardActionButtonsContainer);
        if(showElem?.length) {
            //@ts-ignore
            showElem[0].classList.add(classes.showButtons);
        }
        const hideElem = parentObj?.getElementsByClassName(classes.dateContainer);
        if(hideElem?.length) {
            //@ts-ignore
            hideElem[0].classList.add(classes.hideButtons);
        }
    }, [classes]);

    const hideActionButtons = useCallback((parentId: string) => {
        const parentObj = document.getElementById(parentId);
        const hideElem = parentObj?.getElementsByClassName(classes.callCardActionButtonsContainer);
        if(hideElem?.length) {
            //@ts-ignore
            hideElem[0].classList.remove(classes.showButtons);
        }
        const showElem = parentObj?.getElementsByClassName(classes.dateContainer);
        if(showElem?.length) {
            //@ts-ignore
            showElem[0].classList.remove(classes.hideButtons);
        }
    }, [classes]);

    const makeCall = useCallback((numberToCall: string) => {
        dispatch(actions.setIsCalling.request({
            isCalling: true,
            number: numberToCall
        }));
        dispatch(actions.setNavBarCurrentIndex.request(2));
    }, [dispatch]);

    const hideAllDetails = useCallback(() => {
        const toClose = document.getElementsByClassName(classes.diplayItem) ?? [];
        //@ts-ignore
        for(const itm of toClose) {
            itm.classList.remove(classes.diplayItem);
        }
    }, [classes]); 

    const showDetails = useCallback((detaildId: string) => {
        const detailsObj = document.getElementById(detaildId);
        if(detailsObj) {
            if(detailsObj.classList.contains(classes.diplayItem)) 
            {
                detailsObj.classList.remove(classes.diplayItem);
            }
            else
            {
                hideAllDetails();
                detailsObj.classList.add(classes.diplayItem);
            }
        }
    }, [classes, hideAllDetails]);

    const getContact = useCallback((call: Call) => {
        const number = getNumber(call);
        if(!contacts?.length || !number?.length) {
            return undefined;
        }
        const byExt = contacts.find(e => e.numbers?.ext === number);
        if(byExt) {
            return byExt;
        }
        return undefined;
    }, [contacts]);

    const onScroll = useCallback((e: any) => {
        const bottom = (e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
        const isEmptyCalls = (calls?.length ?? 0) === 0;
        if (bottom && !isEmptyCalls) {
            fetchMoreData();
        }
    }, [fetchMoreData, calls]);

    const getCallImage = useCallback((call: Call) => {
        if(call.direction !== 'outgoing' && call.status === 'accepted') {
            return (<div className={classes.callStatusImage}>
                <img src={IncomingAnsweredSvg} alt="incoming-accepted-img" />
            </div>);
        }
        else if (call.direction !== 'outgoing' && call.status !== 'accepted') {
            return (<div className={classes.callStatusImage}>
                <img src={IncomingMissingSvg} alt="incoming-missing-img" />
            </div>);
        }
        else if(call.direction === 'outgoing' && call.status === 'accepted') {
            return (<div className={classes.callStatusImage}>
                <img src={OutgingAnsweredSvg} alt="outgoing-accepted-img" />
            </div>);
        }
        else if (call.direction === 'outgoing' && call.status !== 'accepted') {
            return (<div className={classes.callStatusImage}>
                <img src={OutgingMissingSvg} alt="outgoing-missing-img" />
            </div>);
        }
        return (<div className={classes.callStatusImage}></div>);
    }, [classes]);

    const getCallStatus = useCallback((call: Call) => {
        const sec = (call?.duration ?? 0) % 60;
        const secStr = sec.toFixed(0) + ' ' + t('screens:contacts.sec');
        let min = Math.trunc(((call?.duration ?? 0) - sec) / 60);
        let hour = 0;
        if(min >= 60) {
            const allMins = min;
            min = allMins % 60;
            hour = Math.trunc((allMins - min) / 60);
        }
        const minStr = min > 0
            ? min.toFixed(0) + ' ' + t('screens:contacts.min') + ' '
            : '';
        const hourStr = hour > 0
            ? hour.toFixed(0) + ' ' + t('screens:contacts.h') + ' '
            : '';
        const duration = hourStr + minStr + secStr;
        switch(call.status) {
            case 'accepted':
                return t(call.direction === 'incoming'
                    ? 'screens:contacts.incomingAccepted'
                    : call.direction === 'forwarded'
                        ? 'screens:contacts.forwardedAccepted'
                        : 'screens:contacts.outgoingAccepted'
                    , {
                    duration: duration
                });
            case 'declined':
                return t(call.direction === 'incoming'
                    ? 'screens:contacts.incomingDeclined'
                    : call.direction === 'forwarded'
                        ? 'screens:contacts.forwardedDeclined'
                        : 'screens:contacts.outgoingDeclined');
            case 'missed':
                return t(call.direction === 'incoming'
                    ? 'screens:contacts.incomingMissed'
                    : call.direction === 'forwarded'
                        ? 'screens:contacts.forwardedMissed'
                        : 'screens:contacts.outgoingMissed');
            case 'error':
            default:
                return t(call.direction === 'incoming'
                    ? 'screens:contacts.incomingError'
                    : call.direction === 'forwarded'
                        ? 'screens:contacts.forwardedError'
                        : 'screens:contacts.outgoingError');
        }
    }, [t]);
    
    const getDateFormatted = useCallback((call: Call) => {
        return convertUtcToUserLocalDate(
            call.connect_time,
            user?.time_zone,
            t
        );
    }, [user, t]);
    
    const getTimeFormatted = useCallback((call: Call, ignore_seconds: boolean) => {
        return convertUtcToUserLocalTime(
            ignore_seconds,
            call.connect_time,
            user?.time_zone,
            t
        );
    }, [user, t]);

    const getDateFormattedInCase = useCallback((call: Call) => {
        const value = getDateFormatted(call);
        if(value === t('screens:contacts.today')) {
            return getTimeFormatted(call, true);
        }
        if(value.length >= 1) {
            return value[0].toUpperCase() + value.slice(1);
        }
        return value;
    }, [getDateFormatted, t, getTimeFormatted]);

    if(initialLoading) {
        return (<div className={classes.rootContainer}>
                <Loader />
            </div>
        );
    }

    if(!isLoadingCalls && (calls?.length ?? 0) === 0) {
        return (<div className={classes.rootContainer}>
                <NoData customText={t('screens:contacts.noData')} />
            </div>
        );
    }

    return (<div className={classes.rootContainer}>
        <div className={classes.scrollDiv} onScroll={onScroll}>
            {calls?.map((call: Call, indx: number) => (<div
                    key={'call_card_container_' + call.call_id + "_" + indx}
                >
                    <div className={classes.callCard}
                        key={'contact_' + call.call_id + "_" + indx}
                        id={'contact_' + call.call_id + "_" + indx}
                        onMouseEnter={() => showActionButtons('contact_' + call.call_id + "_" + indx)}
                        onMouseLeave={() => hideActionButtons('contact_' + call.call_id + "_" + indx)}
                    >
                        <div className={classes.callCardContactInfoContainer}>
                            <div className={classes.callCardAvatarContainer}>
                                <div className={classes.callCardAvatarDiv}>
                                    <img 
                                        src={'https://www.gravatar.com/avatar/' + getContact(call)?.email_md5 + '?s=40&r=g&d=blank'}
                                        className={classes.callCardAvatar}
                                        alt={"contact-gravatar-button" + indx}
                                    />
                                </div>
                                <div className={classes.callCardAvatarAbbreviation}>
                                    <div>{getInitials(getAlias(call))}</div>
                                </div>
                            </div>
                            <div className={classes.callCardDetailsContainer}>
                                <div className={classNames(classes.callCardContactNameContainer, 
                                    call.direction === 'incoming' && call.status !== 'accepted' && classes.missingIncomingCall)}
                                >
                                    {(getAlias(call)?.length ?? 0) > 0 ? getAlias(call) : t('screens:contacts.unnamed') }
                                </div>
                                <div className={classes.callCardContactNumberContainer}>
                                    {
                                        getCallImage(call)
                                    }
                                    {
                                        getContact(call)?.numbers?.ext ? (t('screens:contacts.ext') + ': ' + getContact(call)?.numbers?.ext) : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={classes.callCardActionButtonsContainer}>
                            <div className={classes.infoButton} onClick={() => makeCall(getNumber(call))}>
                                <img src={CallButton} alt="call-button" className='callButton' />
                            </div>
                            <div className={classes.infoButton} onClick={() => showDetails('call_' + call.call_id + "_" + indx + '_details')}>
                                <img src={ContactInfoButton} alt={"contact-info-button" + indx} />
                            </div>
                        </div>
                        <div className={classes.dateContainer}>
                            <div>{getDateFormattedInCase(call)}</div>
                        </div>
                    </div>
                    <div className={classes.callCardDetails}
                        key={'call_' + call.call_id + "_" + indx + '_details'}
                        id={'call_' + call.call_id + "_" + indx + '_details'}
                    >
                        <div className={classNames(classes.callCardDetailsRow, classes.callCardDetailsRowTop)}>
                            {getAlias(call)}{getContact(call)?.numbers?.ext ? (' (' + t('screens:contacts.extLower') + ' ' + getContact(call)?.numbers?.ext + ')') : ''}
                        </div>
                        <div className={classes.callCardDetailsRow}>
                            {getCallStatus(call)}, {t('screens:contacts.dateTimeFormat', {
                                dateFormatted: getDateFormatted(call),
                                timeFormatted: getTimeFormatted(call, false),
                            })}
                        </div>
                        <div onClick={hideAllDetails} className={classes.closeDetailsButtonContainer}>
                            <CancelOutlinedIcon className={classes.closeDetailsButton}  />
                        </div>
                    </div>
                </div>
            ))}
            {isLoadingCalls && (calls?.length ?? 0) > 0 && (
                <div className={classes.scrollMoreContainer}>
                    <BeatLoader color="rgba(61, 91, 104, 0.2)" size={12} />
                </div>
            )}
        </div>
    </div>);
};

export default RecentCalls;