import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from '../translations';

import moment from 'moment';

moment.locale(localStorage.getItem('user_language')?.toLowerCase() || 'en');

i18n.use(initReactI18next).init({
    ns: ['common', 'screens'],
    defaultNS: 'screens',
    resources: {
        en,
    },
    lng: window.localStorage.getItem('user_language') || 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
            if (!format) {
                return value;
            }

            return format.split(',').reduce((value: string, format: string) => {
                return value;
            }, value);
        },
    },
});

export default i18n;
