import { useDispatch, useSelector } from "react-redux";
import { MessageFromWidget, OutgoingCallData, RingMute, UserStatusUpdate } from "./store/types/messagesToWidget";
import { actions } from "./store";
import { useCallback, useEffect } from "react";
import { ReduxState } from "./store/types/store";
import Signaling, { IncomingCallData } from "./webrtc/signaling";
import useEventListener from "./hooks/useEventListener";
import { GetDefaultCountryPayload } from "./store/actions/settings/payload";

const MessagesFromWidgetProcessor: React.FC = () => {
    const dispatch = useDispatch();
    
    const {access_token, register, incomingCallStatus} = useSelector(
        (state: ReduxState) => state.auth,
    );
    
    //@ts-ignore
    const receiveMessage = useCallback((event) => {
        if(!event?.data?.type) return;

        const data = event.data as MessageFromWidget;
        switch(data.type) {
            case 'USER_STATUS_UPDATE':
                const form: UserStatusUpdate = data.data as UserStatusUpdate;
                form.doNotExecuteApi = false;
                dispatch(actions.setRegistrationStatus.request(form));
                break;
            case 'RING_MUTE_UPDATE':
                const form1 = data.data as RingMute;
                dispatch(actions.setMuteStatus.request(form1));
                break;
            case 'RING_FINISH':
                dispatch(actions.setNavBarCurrentIndex.request(2));
                dispatch(actions.setIsCalling.request({
                    isCalling: false,
                }));
                break;
            case 'INCOMING_CALL_TRIGGER':
                dispatch(actions.setIsCalling.request({
                    isCalling: true
                }));
                dispatch(actions.setNavBarCurrentIndex.request(2));
                dispatch(actions.setIncomingCallStatus.request(data.data as IncomingCallData));
                break;
            case 'ACCEPT_CALL':
                if(!incomingCallStatus?.call_id) {
                    return;
                }
                if(incomingCallStatus) {
                    //@ts-ignore
                    window.signaling?.answer(incomingCallStatus);
                }
                break;
            case 'START_OUTGOING_CALL':
                if(register) {
                    const phone = (data.data as OutgoingCallData)?.number;
                    dispatch(actions.setIsCalling.request({
                        isCalling: true,
                        number: phone
                    }));
                    dispatch(actions.setNavBarCurrentIndex.request(2));
                }
                break;
            case 'WIDGET_CONFIG_COUNTRY':
                dispatch(actions.setDefaultCountryInitially.request(data.data as GetDefaultCountryPayload));
                break;
            default:
                break;
        }
    }, [dispatch, incomingCallStatus, register]);

    useEventListener('message', receiveMessage);
    
    useEffect(() => {
        let shouldInitSignaling = false;
        let shouldDestroySignaling = false;

        if(!access_token || !register) {
            shouldDestroySignaling = true;
        }
        
        //@ts-ignore
        if(window.signaling && window.signaling.token !== access_token) {
            shouldDestroySignaling = true;
            shouldInitSignaling = true;
        }

        //@ts-ignore
        if(!window.signaling && access_token && register) {
            shouldInitSignaling = true;
        }

        if(shouldDestroySignaling) {
            //@ts-ignore
            window.signaling?.callHangup();
            //@ts-ignore
            window.signaling?.disconnect();
            //@ts-ignore
            window.signaling = null;
        }

        const callback = (self: any, status: any, additionalInfo: any) => {
            if(status === 'Disconnected' && (
                    //the widget open twice
                    additionalInfo === 4431 || additionalInfo === 1006
                    //connection error
                    || additionalInfo === 4000 || additionalInfo === 1011
                    //protocol error
                    || additionalInfo === 4610 || additionalInfo === 490
                )) {
                const form: UserStatusUpdate = 
                {
                    register: false,
                    doNotExecuteApi: true
                };
                dispatch(actions.setRegistrationStatus.request(form));
            }
        };

        if(shouldInitSignaling) {
            var v = new Signaling(access_token || '', callback);
            //@ts-ignore
            window.signaling = v;
            v.connect();
        }

        //@ts-ignore
        dispatch(actions.updateWebSignalingState.request(window.signaling));
    }, [access_token, dispatch, register]);

    return (<></>);
};

export default MessagesFromWidgetProcessor;