import {ActionType, createReducer} from 'typesafe-actions';
import { APIError } from "../../types/errors";
import { actions } from "../..";
import { Call } from '../../actions/recentCalls/payload';

export interface RecentCallsReducerStateType {
    calls: Call[] | null;
    isLoadingCalls: boolean;
    loadingError?: APIError | null;
    initialLoading: boolean;
    items_total: number | null;
}

const getInitRecentCallsState = function() {
    return {
        calls: null,
        isLoadingCalls: false,
        loadingError: null,
        initialLoading: true,
        items_total: null,
    } as RecentCallsReducerStateType;
}

export const initialState: RecentCallsReducerStateType = getInitRecentCallsState();

export type CallsActionsType = ActionType<typeof actions>;

const CallsReducer = createReducer<RecentCallsReducerStateType, CallsActionsType>(
    initialState,
)
    .handleAction(actions.getCalls.request, (state, action) => {
        return {
            ...state,
            isLoadingCalls: true,
            loadingError: null
        };
    })
    .handleAction(actions.getCalls.success, (state, action) => {
        const newCalls = action.payload.items ?? [];
        const oldCalls = state?.calls ?? [];
        const finalList = [...oldCalls];
        let insrtIndex = 0;
        for(const nc of newCalls) {
            const existsIndx = oldCalls.findIndex(e => 
                e.call_id === nc.call_id && 
                e.callee === nc.callee && 
                e.caller === nc.caller && 
                e.connect_time === nc.connect_time && 
                e.direction === nc.direction && 
                e.disconnect_reason === nc.disconnect_reason && 
                e.duration === nc.duration && 
                e.status === nc.status 
            );
            if(existsIndx === -1) {
                if(action.payload.page === 1) {
                    finalList.splice(insrtIndex, 0, nc);
                    insrtIndex++;
                } else {
                    finalList.push(nc);
                }
            }
        }
        return {
            ...state,
            calls: finalList,
            isLoadingCalls: false,
            loadingError: null,
            initialLoading: false,
            items_total: action.payload.items_total,
        };
    })
    .handleAction(actions.getCalls.failure, (state, action) => {
        return {
            ...state,
            isLoadingCalls: false,
            loadingError: action.payload,
            initialLoading: false,
            items_total: 0,
        };
    })
;

export default CallsReducer;