import {put, takeLatest} from 'redux-saga/effects';
import * as actions from '../../actions/contacts';
import { AxiosResponse } from 'axios';
import { api } from '../../services/axios';
import { EndPoints } from '../../endpoints';
import { Contact } from '../../actions/contacts/payload';
import { md5 } from 'js-md5';
import { getAlis } from '../../../helpers/AliasHelper';

export function* getContacts() {
    try {
        const res: AxiosResponse<{
            items: Contact[]
        }> = yield api.get(EndPoints.ContactsList);

        const items: Contact[] = res.data?.items ?? [];
        for(const contact of items) {
            contact.email_md5 = (contact?.email?.length ?? 0) > 0 
                ? md5(contact.email ?? '')
                : undefined;
            
            contact.displayName = getAlis(contact);
        }

        items.sort((a: Contact, b: Contact) => {
            return (a.displayName ?? '').localeCompare(b.displayName ?? '');
        })

        yield put(
            actions.getContacts.success(items),
        );
    } catch (err) {
        // @ts-ignore
        yield put(actions.getContacts.failure(err));
    }
}

export const contactsSaga = [
    takeLatest(actions.getContacts.request, getContacts),
];
