import { makeStyles } from "tss-react/mui";
import { Colors } from "../../styles/Colors";

export const useStyles = makeStyles()(() => {
    return {
        rootContainer: {
            width: '100%',
            height: 'calc(100% - 56px)',
            background: Colors.White,
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        },
        searchContainer: {
            width: 'calc(100% - 32px)',
            height: 40,
            padding: '8px 16px 8px 16px',
        },
        searchTextBox: {
            height: 32,
            width: 281,

            '& .MuiInputBase-root': {
                height: 32,
                lineHeight: 1,
                fontSize: 11,
                padding: 0,
                borderRadius: 8,
                background: 'rgba(243, 245, 246, 1)',
                border: 'none'
            },

            '& input': {
                height: '32px !important',
                paddingTop: 0,
                paddingBottom: 0,
            },

            '& svg': {
                marginLeft: 8,
                marginTop: 4
            }
        },
        contactsListContainer: {
            width: 'calc(100% - 32px)',
            height: 'fit-content',
            minHeight: 'calc(100% - 52px)',
            padding: '8px 16px 0px 16px',
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'column',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        contactCard: {
            width: '100%',
            height: 42,
            userSelect: 'none',
            padding: '7px 0px 7px 0px',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            overflow: 'hidden',
            minHeight: 42,
        },
        contactCardContactInfoContainer: {
            width: 222,
            height: 42,
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row',
        },
        contactCardAvatarDiv: {
            width: 40,
            height: 40,
            zIndex: 2,
        },
        contactCardAvatarContainer: {
            background: 'rgba(61, 91, 104, 0.2)',
            width: 40,
            height: 40,
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            position: 'relative'
        },
        contactCardAvatar: {
            borderRadius: 20,
        },
        contactCardAvatarAbbreviation: {
            fontSize: 14,
            fontWeight: 500,
            textAlign: 'center',
            padding: '10.77px 0px',
            color: 'rgba(61, 91, 104, 1)',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: 40,
            zIndex: 1
        },
        contactCardDetailsContainer: {
            width: 152,
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginLeft: 8,
            maxHeight: 60,
            minHeight: 42,
        },
        contactCardContactNameContainer: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '14px',
            textAlign: 'left',
            color: 'rgba(44, 40, 37, 1)',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            height: 'fit-content',
        },
        contactCardContactNameContainerTooLong: {
            fontSize: 12,
            lineHeight: '12px',
        },
        contactCardContactNumberContainer: {
            fontSize: 11,
            fontWeight: 400,
            lineHeight: '15.4px',
            textAlign: 'left',
            color: 'rgba(139, 157, 164, 1)'
        },
        contactCardActionButtonsContainer: {
            padding: 0,
            height: 42,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 64,
            visibility: 'hidden'
        },
        infoButton: {
            width: 32,
            height: 32,
            cursor: 'pointer',
            marginTop: 1,

            '& img': {
                width: 32,
                height: 32,
            },

            "& img.callButton": {
                width: 28,
                height: 28,
                marginTop: 2,
            }
        },
        showButtons: {
            visibility: 'visible'
        },
        contactStatusContainer: {
            paddingTop: 25,
            paddingRight: 10,
            position: 'absolute',
            zIndex: 2,
            right: -12,
            bottom: -3,
        },
        contactStatus: {
            width: 12,
            height: 12,
            borderRadius: 8,
            border: '2px solid white',
        },
        contactStatusRegistered: {
            backgroundColor: '#75B943'
        },
        contactStatusNotRegistered: {
            backgroundColor: '#E74C3C'
        },
        contactCardDetails: {
            background: Colors.White,
            height: 'fit-content',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
        },
        contactCardDetailsRow: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            display: 'none'
        },
        diplayItem: {
            display: 'flex',
            '& *': {
                display: 'flex',
            }
        },
        contactCardDetailsRowName: {
            color: 'rgba(139, 157, 164, 1)',
            fontSize: 11,
            fontWeight: 400,
            lineHeight: '15.4px',
            textAlign: 'left',
            width: 80
        },
        contactCardDetailsRowValue: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '18.76px',
            textAlign: 'left',
            color: 'rgba(44, 40, 37, 1)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            maxWidth: 184
        },
        detailsRowWithButton: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%'
        },
        closeDetailsButton: {
            height: 18,
            color: 'rgba(61, 91, 104, 0.64)',
            cursor: 'pointer'
        }
    };
});