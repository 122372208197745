import { APIErrorInterface } from "../../types/errors";
import {ActionType, createReducer} from 'typesafe-actions';
import * as actions from '../../actions/auth';
import { User } from "../../actions/payloads";
import usePostMessageToWidget from "../../../hooks/usePostMessageToWidget";
import { md5 } from 'js-md5';
import Signaling, { IncomingCallData } from "../../../webrtc/signaling";
import { getAlis, getAliasForEntity } from "../../../helpers/AliasHelper";

export interface AuthReducerStateType {
    access_token?: string | null;
    login?: string | null;
    errors?: APIErrorInterface | null;
    register?: boolean;
    registrationStatusIsPending?: boolean;
    registrationStatusErrors?: APIErrorInterface | null;
    webTritSignaling: Signaling | null;
    user?: User;
    navBarCurrentIndex: number;
    incomingCallStatus: IncomingCallData | null;
}

const getInitAuthState = function() {
    return {
        login: localStorage.getItem('user_login'),
        access_token: localStorage.getItem('access_token'),
        webTritSignaling: null,
        navBarCurrentIndex: 1
    } as AuthReducerStateType;
}

export const initialState: AuthReducerStateType = getInitAuthState();

export type AuthActionsType = ActionType<typeof actions>;

const signInReducer = createReducer<AuthReducerStateType, AuthActionsType>(
        initialState,
    )
        .handleAction(actions.logIn.request, (state, action) => ({
            ...state,
            login: action.payload.login,
            access_token: undefined,
            inProgress: true,
        }))
        .handleAction(actions.logIn.success, (state, action) => {
            const {access_token} = action.payload;
            return {
                ...state,
                access_token,
                errors: null,
                inProgress: false,
            };
        })
        .handleAction(actions.logIn.failure, (state, action) => {
            return {
                ...state,
                errors: action.payload.response?.data,
                inProgress: false,
            };
        })
        .handleAction(actions.logOut.success, (state) => ({
            ...state,
            login: undefined,
            access_token: undefined,
        }))
        .handleAction(actions.logOut.failure, (state) => ({
            ...state,
            login: undefined,
            access_token: undefined,
        }))
        .handleAction(actions.getRegistrationStatus.request, (state) => ({
            ...state,
            registrationStatusIsPending: true,
        }))
        .handleAction(actions.getRegistrationStatus.success, (state, action) => ({
            ...state,
            register: action.payload.register,
            registrationStatusIsPending: false,
        }))
        .handleAction(actions.getRegistrationStatus.failure, (state, action) => ({
            ...state,
            registrationStatusErrors: action.payload.response?.data,
            registrationStatusIsPending: false,
        }))
        .handleAction(actions.getUserDetails.request, (state) => ({
            ...state,
        }))
        .handleAction(actions.getUserDetails.success, (state, action) => ({
            ...state,
            user: action.payload
        }))
        .handleAction(actions.setRegistrationStatus.success, (state, action) => {
            usePostMessageToWidget({
                type: "USER_STATUS",
                data: {
                    isLoggedIn: true,
                    userMail: md5(state.user?.email ?? ''),
                    register: action.payload.register,
                    aliace: state.user ? getAlis(state.user) : '',
                    initial: state.user ? getAliasForEntity(state.user) : '',
                    isCalling: false
                }
            });
            return {
                ...state,
                register: action.payload.register,
            };
        })
        .handleAction(actions.setRegistrationStatus.failure, (state, action) => ({
            ...state,
            registrationStatusErrors: action.payload.response?.data,
        }))
        .handleAction(actions.updateWebSignalingState.request, (state, action) => {
            return {
                ...state,
                webTritSignaling: action.payload
            };
        })
        .handleAction(actions.updateWebSignalingCallBackFunc.request, (state, action) => { 
            const webTritSignaling = state.webTritSignaling;
            if(webTritSignaling && action.payload) {
                webTritSignaling.statusCallback = action.payload;
            }
            return {
                ...state,
                webTritSignaling: webTritSignaling
            };
        })
        .handleAction(actions.setNavBarCurrentIndex.request, (state, action) => ({
            ...state,
            navBarCurrentIndex: action.payload
        }))
        .handleAction(actions.setIncomingCallStatus.request, (state, action) => ({
            ...state,
            incomingCallStatus: action.payload
        }))
;

export default signInReducer;