import axios, { AxiosHeaders } from 'axios';
import { config } from '../config';

export const api = axios.create();

export const PAYLOAD_KEY = 'params=';
export const CONTENT_TYPE = 'application/json';

const CHECK_AUTH_FAILD_CODE = 'Server.Session.check_auth.auth_failed';

export const setLogoutErrorVisible = (value = true) => {
    localStorage.setItem('session_error_visible', value ? 'true' : 'false');
};

export const isLogoutErrorVisible =() => localStorage.getItem('session_error_visible') === 'true';

const logoutGuardFunc = () => localStorage.getItem('session_expired') === 'true';
const setSessionExpired = (value = true) => {
    localStorage.setItem('session_expired', value ? 'true' : 'false');
};

export const clearStorageDatBeforeLogout = () =>
{
    localStorage.removeItem('access_token');
    localStorage.removeItem('user_login');
    localStorage.removeItem('request_id');
}

export const logOut = (delay = 3000, reload = true) => {
    clearStorageDatBeforeLogout();

    if(reload)
    {
        setTimeout(() => {
            window.location.reload();
            setSessionExpired(false);
        }, delay);
    }
};

api.interceptors.request.use(
    (axiosConfig) => {
        const obj = {
            ...axiosConfig,
            baseURL: config?.baseURL as string | undefined,
        };
        if(!obj.headers) {
            obj.headers = new AxiosHeaders();
        }
        const token = localStorage.getItem('access_token');
        if(token) {
            obj.headers.setAuthorization('Bearer ' + token);
        }
        const requestId = localStorage.getItem('request_id');
        if(requestId?.length) {
            obj.headers.set('X-Request-Id', requestId);
        }
        obj.headers.setContentType(CONTENT_TYPE);
        obj.headers.setAccept(CONTENT_TYPE);
        obj.withCredentials = false;
        return obj;
    },
    (error) => {
        console.error(error);
        return Promise.reject(error);
    },
);

api.interceptors.response.use(undefined, (error) => {

    const randomInterval = (min: number, max: number) => { 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const logoutGuard = logoutGuardFunc();

    if (
        (error.response?.data.faultcode === CHECK_AUTH_FAILD_CODE ||
            error.response?.data.faultcode === CHECK_AUTH_FAILD_CODE
        ) && !logoutGuard
    ) {
        setTimeout(() => {
            if(!logoutGuardFunc() && !isLogoutErrorVisible()) {
                setLogoutErrorVisible(true)
            }
            setSessionExpired();
            logOut();
        }, randomInterval(1, 20));
    }
    if ((error.response?.status === 500 || error.response?.status === 401) &&
        !logoutGuard
    ) {
        setSessionExpired();
        if(localStorage.getItem('user_login'))
        {
            logOut();
        }
    }

    return Promise.reject(error);
});