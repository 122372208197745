import { PadkeyProps, useStyles } from "./PadImagekey.utils";
import classNames from 'classnames';

const PadImagekey: React.FC<PadkeyProps> = ({
    icon,
    label,
    onClick,
    disabled
}) => {
    const { classes } = useStyles();

    return (<div className={classes.buttonContainer}>
        <div className={classes.iconContainer}>
            <div className={classNames(classes.keymain, disabled && classes.keymainDisabled)} 
                onClick={() => {
                    if(!disabled) {
                        onClick?.();
                    }
                }}
            >
                <div className={classNames(classes.keymainVertical, disabled && classes.keymainVerticalDisabled)}>
                    {icon}
                </div>
            </div>
        </div>
        <div className={classes.labelContainer}>
            {label}
        </div>
    </div>);
}

export default PadImagekey;