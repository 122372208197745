import { useCallback } from "react";
import { PadkeyProps, useStyles } from "./Padkey.utils";
import classNames from "classnames";

const Padkey: React.FC<PadkeyProps> = ({
    chars,
    clickedChar,
    replaceLastChar,
    keyUp,
    className,
    children
}) => {
    const { classes } = useStyles();

    const mousedown = useCallback(() => {
        clickedChar?.(chars[0]);
        
        //@ts-ignore
        window.infiniteFunc = (kpt: number, chs: string) => {
            setTimeout(() => {
                if(kpt === 0) {
                    return;
                }
                //@ts-ignore
                if(!window.infiniteFunc) {
                    return;
                }
                const now = new Date().valueOf();
                const dif = now - kpt;
                const delayOfChar = 1000;//ms
                if(dif >= delayOfChar) {
                    let indx = Math.trunc(dif / delayOfChar);
                    if (indx >= chs.length) {
                        indx = chs.length - 1;
                    }
                    replaceLastChar?.((chs[indx] + '').toUpperCase());
                } 
                //@ts-ignore
                window.infiniteFunc?.(kpt, chs);
            }, 500);
        };

        const now = new Date().valueOf();
        setTimeout(() => {
            //@ts-ignore
            window.infiniteFunc?.(now, chars);
        }, 200);
    }, [clickedChar, chars, replaceLastChar]);
    
    const mouseup = useCallback(() => {
        //@ts-ignore
        const wasSetup = window.infiniteFunc !== undefined;
        //@ts-ignore
        window.infiniteFunc = undefined;
        if(wasSetup) {
            keyUp?.();
        }
    }, [keyUp]);
    
    return (<div
            className={classNames(classes.keymain, className)} 
            onMouseDown={mousedown}
            onMouseUp={mouseup}
            onMouseLeave={mouseup}
        >
            {children ? (<>{children}</>) : (
                <div key={'keypad_key_0'} className={classes.keyMain}>{(chars ?? ' ')?.[0]}</div>
            )}
            {chars.length > 1 && (
                <div className={classes.subKeysContainer}>
                    {(chars ?? '').toUpperCase().substring(1).split('').map((ch, indx) => {
                        return (
                            <div key={'keypad_key_' + indx} className={classes.keySub}>{ch}</div>);
                    })}
                </div>
            )}
    </div>);
}

export default Padkey;