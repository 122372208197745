import { makeStyles } from "tss-react/mui";

export type PadkeyProps = {
    chars: string;
    clickedChar: (ch: string) => void,
    replaceLastChar: (ch: string) => void,
    keyUp: () => void,
    className?: string,
    children?: React.ReactNode
};

export const useStyles = makeStyles()(() => {
    return {
        keymain: {
            width: 40,
            height: 40,
            borderRadius: 20,
            background: 'rgba(243, 245, 246, 1)',
            border: '1px solid rgba(235, 241, 243, 1)',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            lineHeight: '12px',
            cursor: 'pointer',
            userSelect: 'none'
        },
        keyMain: {
            fontFamily: 'Montserrat',
            fontSize: 20,
            fontWeight: 400,
            textAlign: 'center',
            color: 'rgba(61, 91, 104, 0.6)',
        },
        subKeysContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row'
        },
        keySub: {
            fontFamily: 'Montserrat',
            fontSize: 11.5,
            fontWeight: 700,
            textAlign: 'center',
            color: 'rgba(61, 91, 104, 0.6)',
            marginTop: 4,
        }
    };
});