import { createAsyncAction } from 'typesafe-actions';
import { APIError } from '../../types/errors';
import { ApiLoginPayload, GetRegistrationStatusPayload, LogOutRequest, SessionIdPayload, User } from './payloads';
import { UserStatusUpdate } from '../../types/messagesToWidget';
import Signaling, { IncomingCallData } from '../../../webrtc/signaling';

export const logIn = createAsyncAction(
    'USER_SIGN_IN',
    'USER_SIGN_IN_SUCCESSES',
    'USER_SIGN_IN_FAILED',
)<ApiLoginPayload, SessionIdPayload, APIError>();

export const getRegistrationStatus = createAsyncAction(
    'GET_REGISTRATION_STATUS',
    'GET_REGISTRATION_STATUS_SUCCESSES',
    'GET_REGISTRATION_STATUS_FAILED',
)<undefined, GetRegistrationStatusPayload, APIError>();

export const setNavBarCurrentIndex = createAsyncAction(
    'SET_NAVBAR_INDEX',
    'SET_NAVBAR_INDEX_SUCCESSES',
    'SET_NAVBAR_INDEX_FAILED',
)<number, undefined, undefined>();

export const setRegistrationStatus = createAsyncAction(
    'SET_REGISTRATION_STATUS',
    'SET_REGISTRATION_STATUS_SUCCESSES',
    'SET_REGISTRATION_STATUS_FAILED',
)<UserStatusUpdate, UserStatusUpdate, APIError>();

export const updateRegistrationStatus = createAsyncAction(
    'UPDATE_REGISTRATION_STATUS',
    'UPDATE_REGISTRATION_STATUS_SUCCESSES',
    'UPDATE_REGISTRATION_STATUS_FAILED',
)<GetRegistrationStatusPayload, GetRegistrationStatusPayload, APIError>();

export const logOut = createAsyncAction(
    'USER_SIGN_OUT',
    'USER_SIGN_OUT_SUCCESSES',
    'USER_SIGN_OUT_FAILED',
)<LogOutRequest, undefined, APIError>();

export const getUserDetails = createAsyncAction(
    'USER_DETAILS',
    'USER_DETAILS_SUCCESSES',
    'USER_DETAILS_FAILED',
)<undefined, User, APIError>();

export const updateWebSignalingState = createAsyncAction(
    'UPDATE_WEB_SIGNALING_STATE',
    'UPDATE_WEB_SIGNALING_STATE_SUCCESSES',
    'UPDATE_WEB_SIGNALING_STATE_FAILED',
)<Signaling | null, undefined, undefined>();

export const updateWebSignalingCallBackFunc = createAsyncAction(
    'UPDATE_WEB_SIGNALING_FUNC',
    'UPDATE_WEB_SIGNALING_FUNC_SUCCESSES',
    'UPDATE_WEB_SIGNALING_FUNC_FAILED',
)<((signaling: Signaling, status: string, additionalInfo: string | IncomingCallData | null) => void) | null, undefined, undefined>();

export const setIncomingCallStatus = createAsyncAction(
    'SET_INCOMING_CALL_STATUS',
    'SET_INCOMING_CALL_STATUS_SUCCESSES',
    'SET_INCOMING_CALL_STATUS_FAILED',
)<IncomingCallData | null, undefined, undefined>();