import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../store/types/store';
import { actions } from '../../store';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { SidebarProps, useStyles } from './Sidebar.utils';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { RoutesAutorized } from '../../App';
import { useNavigate } from 'react-router';
import Config from '../../config.json';
import usePostMessageToWidget from '../../hooks/usePostMessageToWidget';
import { md5 } from 'js-md5';
import { getAliasForEntity, getAlis } from '../../helpers/AliasHelper';

const Sidebar: React.FC<SidebarProps> = ({
    children
}) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const { t } = useTranslation();
    const navigation = useNavigate();

    const {login, access_token, user, register, navBarCurrentIndex} = useSelector(
        (state: ReduxState) => state.auth,
    );

    const isCalling = useSelector(
        (state: ReduxState) => state?.keypad?.isCalling ?? false,
    );

    useEffect(() => {
        if(login) {
            dispatch(actions.getRegistrationStatus.request());
            dispatch(actions.getUserDetails.request());
            dispatch(actions.getDefaultCountry.request());
        }
    }, [dispatch, login]);

    const isLoggedIn = useMemo(() => {
        return (access_token?.length ?? 0) > 0
    }, [access_token]);

    useEffect(function () {
        const emailMd5 = ((user?.email?.length ?? 0) > 0)
            ? md5(user?.email ?? '') + ''
            : null;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        usePostMessageToWidget({
                type: "USER_STATUS",
                data: {
                    isLoggedIn: isLoggedIn,
                    userMail: emailMd5,
                    register: register ?? false,
                    aliace: user ? getAlis(user) : '',
                    initial: user ? getAliasForEntity(user) : '',
                    isCalling: isCalling
                }
            });
    }, [isLoggedIn, user, register, isCalling]);
    
    const setValue = useCallback((newValue: number) => {
        const route = RoutesAutorized[newValue]?.path;
        if(route) {
            navigation(Config.BASE_PATH + route);
            dispatch(actions.setNavBarCurrentIndex.request(newValue));
        }
    }, [navigation, dispatch]);

    useEffect(() => {
        const currentItem = RoutesAutorized[navBarCurrentIndex].path;
        navigation(Config.BASE_PATH + currentItem);
    }, [navBarCurrentIndex, navigation])

    if(!isLoggedIn) {
        return (<div style={{height: 41}}></div>);
    }

    return (<>
        {children}
        <div className={classNames(classes.container, "root-sidebar-class")}>
            <BottomNavigation
                showLabels
                value={navBarCurrentIndex}
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
            >
                <BottomNavigationAction 
                    label={t('screens:sideBar.recentCalls')}
                    icon={<ScheduleOutlinedIcon />}
                    className={classNames(classes.navItem, navBarCurrentIndex === 0 && classes.selectedClass)}
                />
                <BottomNavigationAction
                    label={t('screens:sideBar.contacts')}
                    icon={<AccountCircleOutlinedIcon />}
                    className={classNames(classes.navItem, navBarCurrentIndex === 1 && classes.selectedClass)}
                />
                <BottomNavigationAction
                    label={t('screens:sideBar.keypad')}
                    icon={<DialpadOutlinedIcon />}
                    className={classNames(classes.navItem, navBarCurrentIndex === 2 && classes.selectedClass)}
                />
                <BottomNavigationAction
                    label={t('screens:sideBar.settings')}
                    icon={<SettingsOutlinedIcon />}
                    className={classNames(classes.navItem, navBarCurrentIndex === 3 && classes.selectedClass)}
                />
            </BottomNavigation>
        </div>
    </>);
};

export default Sidebar;