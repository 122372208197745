import { makeStyles } from "tss-react/mui";
import { Colors } from "../../styles/Colors";
import { ReactNode } from "react";

export type SidebarProps = {
    children?: ReactNode; 
}

export const useStyles = makeStyles()(() => {
    return {
        container: {
            background: Colors.Background,
            '& .MuiBottomNavigation-root': {
                background: 'transparent'
            }
        },
        navItem: {
            padding: 0,
            color: Colors.Secondary2,

            '& .MuiBottomNavigationAction-label': {
                color: Colors.Secondary2,
                fontSize: 10,
                fontWeight: 500,
                lineHeight: '16px',
                opacity: 0.54,
            },
            
            '& svg': {
                height: 24,
                width: 24,
                opacity: 0.54,
                '& path': {
                    fill: Colors.Secondary2,
                }
            },
        },
        
        selectedClass: {
            '& svg': {
                height: 24,
                width: 24,
                opacity: 1,
                '& path': {
                    fill: Colors.Primary2,
                }
            },
            
            '& .MuiBottomNavigationAction-label': {
                opacity: 1,
                color: Colors.Primary2,
                fontSize: 10,
                fontWeight: 500,
                lineHeight: '16px',
            }
        },
    };
});