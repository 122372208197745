import React from 'react';
import classNames from 'classnames';
import { makeStyles } from "tss-react/mui";

type LoaderProps = {
    customClass?:string
};

export const useStyles = makeStyles()(() => {
    return {
        timelineItem: {
            backgroundColor: 'transparent',
            paddingLeft: 2,
            paddingRight: 2,
            margin: '0 auto',
            width: 'calc(100% - 4px)',
            height: '100%',
        },

        animatedBackground: {
            animationDuration: '1.5s',
            animationFillMode: 'forwards',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear',
            animationName: 'placeHolderShimmer',
            background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
            backgroundSize: '800px 104px !important',
            height: '100%',
            position: 'relative',
            overflow: 'hidden',
        },

        backgroundMasker: {
            background: '#fff',
            position: 'absolute'
        },

        headerTop: {
            top: 0,
            left: 40,
            right: 0,
            height: 10,
        },

        headerBottom: {
            left: 40,
            right: 0,
            top: 18,
            height: 6,
        },

        subheaderBottom: {
            left: 40,
            right: 0,
            top: 30,
            height: 10,
        },

        headerLeft: {
            top: 10,
            left: 40,
            height: 8,
            width: 10,
        },

        subheaderLeft: {
            left: 40,
            width: 10,
            top: 24,
            height: 6,
        },

        headerRight: {
            top: 10,
            height: 8,
            width: 'auto',
            left: 300,
            right: 0,
        },

        subheaderRight: {
            top: 24,
            height: 6,
            width: 'auto',
            right: 0,
            left: 230,
        },
        
        round: {
            top: 0,
            left: 0,
            width: 40,
            height: 40,
            background: 'white',
        },
        
        roundInternal: {
            top: 0,
            left: 0,
            width: 40,
            height: 40,
            background: 'linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%)',
            borderRadius: 20,
            position: 'absolute',
        },

        fullWidth: {
            top: 40,
            left: 0,
            width: '100%',
            height: 6,
        }
    };
});

const Loader: React.VFC<LoaderProps> = ({
    customClass
}) => {
    const { classes } = useStyles();

    const stepSize = 46;
    const top = 0;

    return (
            <div className={classes.timelineItem}>
                <div className={classes.animatedBackground}>
                    {Array.from(Array(10).keys()).map((indx) => (
                        <div key={'uit_' + indx}>
                            <div style={{top: top + 0 + ((indx) * stepSize)}} className={classNames(classes.backgroundMasker, classes.round)}></div>
                            <div style={{top: top + 0 + ((indx) * stepSize)}} className={classNames(classes.animatedBackground, classes.roundInternal)}></div>
                            <div style={{top: top + 0 + ((indx) * stepSize)}} className={classNames(classes.backgroundMasker, classes.headerTop)}></div>
                            <div style={{top: top + 10 + (indx * stepSize)}} className={classNames(classes.backgroundMasker, classes.headerLeft)}></div>
                            <div style={{top: top + 10 + (indx * stepSize)}} className={classNames(classes.backgroundMasker, classes.headerRight)}></div>
                            <div style={{top: top + 18 + (indx * stepSize)}} className={classNames(classes.backgroundMasker, classes.headerBottom)}></div>
                            <div style={{top: top + 24 + (indx * stepSize)}} className={classNames(classes.backgroundMasker, classes.subheaderLeft)}></div>
                            <div style={{top: top + 24 + (indx * stepSize)}} className={classNames(classes.backgroundMasker, classes.subheaderRight)}></div>
                            <div style={{top: top + 30 + (indx * stepSize)}} className={classNames(classes.backgroundMasker, classes.subheaderBottom)}></div>
                            <div style={{top: top + 40 + (indx * stepSize)}} className={classNames(classes.backgroundMasker, classes.fullWidth)}></div>
                        </div>
                    ))}
                </div>
            </div>
    );
};

export default Loader;
