import { createAsyncAction } from 'typesafe-actions';
import { IsCallingPayload, RingMute } from '../../types/messagesToWidget';

export const setMuteStatus = createAsyncAction(
    'SET_MUTE',
    'SET_MUTE_SUCCESSES',
    'SET_MUTE_FAILED',
)<RingMute, undefined, undefined>();

export const setIsCalling = createAsyncAction(
    'SET_IS_CALLING',
    'SET_IS_CALLING_SUCCESSES',
    'SET_IS_CALLING_FAILED',
)<IsCallingPayload, undefined, undefined>();