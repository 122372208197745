
export const DTMF_DURATION = 500;
export const DTMF_TONE_GAP = 50;

export type PeerConnectionProps = {
    iceCandidateCallback: (candidate: any) => void,
    negotiationNeededCallback: (event: any) => void,
    addStreamCallback: (stream: MediaStream, value: boolean) => void,
    errorCallback: (error: Error) => void,
}

export default class PeerConnection {
    _peerConnection: RTCPeerConnection | null = null;
    _props: PeerConnectionProps | null = null;
    localStream: MediaStream | null = null;
    remoteStream: MediaStream | null = null;
    statsInterval: any = null;
    isNoErrors: boolean = true;
    _dtmfSender: any = null;

    constructor(props: PeerConnectionProps) {
        this._peerConnection = null;
        this._props = props;
        this.isNoErrors = true;
        this.localStream = null;
        this.remoteStream = null;
        this.statsInterval = null;
    }

    async create() {
        const pc = new RTCPeerConnection({
            iceServers: [{
            urls: 'stun:stun.l.google.com:19302',
            }],
        })
        pc.onicecandidate = (event) => this.handleIceCandidate(event);
        pc.onnegotiationneeded = (event) => this.handleNegotiationNeeded(event);
        pc.ontrack = (event) => this.handleTrack(event)
        this._peerConnection = pc;
        this.isNoErrors = true;
    }

    close() {
        if (this.statsInterval) {
            clearInterval(this.statsInterval);
        }
        try {
            if (this._peerConnection) {
                this._peerConnection.close();
                this._peerConnection.onconnectionstatechange = null;
                this._peerConnection.ondatachannel = null;
                this._peerConnection.onicecandidate = null;
                this._peerConnection = null;
            }
        } catch {
        }
        try
        {
            if (this.localStream) {
                this.localStream.getTracks().forEach((track) => track.stop());
                this.localStream = null;
            }
        }
        catch {
        }

        try {
            if (this.remoteStream) {
                this.remoteStream.getTracks().forEach((track) => track.stop());
                this.remoteStream = null;
            }
        } catch {
        }
    }

    addTracks(stream: MediaStream) {
        for (let i = 0; i < stream.getTracks().length; i += 1) {
            const track = stream.getTracks()[i];
            this._peerConnection?.addTrack(track, stream);
        }
    }

    async createOffer() {
        const offer = await this._peerConnection?.createOffer();
        await this._peerConnection?.setLocalDescription(offer);
    }

    async createAnswer() {
        const answer = await this._peerConnection?.createAnswer();
        await this._peerConnection?.setLocalDescription(answer);
    }

    getLocalDescription() {
        return this._peerConnection?.localDescription;
    }

    async setRemoteDescription(jsep: RTCSessionDescriptionInit) {
        const remoteDescription = new RTCSessionDescription(jsep);
        await this._peerConnection?.setRemoteDescription(remoteDescription);
    }

    async setLocalStreams(media: any) {
        try {
            media.video = false;
            const stream = await navigator.mediaDevices.getUserMedia(media);
            this.localStream = stream;//a new 
            this.addTracks(stream);
            this._props?.addStreamCallback(stream, false);
        } catch (e) {
            this.isNoErrors = false;
            //@ts-ignore
            this._props?.errorCallback(new Error(e?.message));
        }
    }

    handleIceCandidate(event: any) {
        console.log('[PC] handle IceCandidate event:', event);
        this._props?.iceCandidateCallback(event.candidate);
    }

    handleTrack(event: any) {
        console.log('[PC] handle Track event:', event);
        if (event.streams !== null && event.streams.length > 0) {
            this.remoteStream = event.streams[0];
            this._props?.addStreamCallback(event.streams[0], true);
        }
    }

    handleNegotiationNeeded(event: any) {
        console.log('[PC] handle NegotiationNeeded event:', event);
        this._props?.negotiationNeededCallback(event);
    }

    initGetStats(id: any, interval: any) {
        this.statsInterval = setInterval(() => {
            this._peerConnection?.getStats(null).then((stats) => {
                //@ts-ignore
            const rec = this.defStatRecord;
            rec.id = id;
            //@ts-ignore
            this.defStatRecord.index++;
            //@ts-ignore
            let video = this.defStatVideoRecord;
            let video_exist = false;
            let rtt = null;
            stats.forEach((report) => {
                switch (report.type) {
                case 'inbound-rtp':
                    switch (report.kind) {
                    case 'audio':
                        rec.audio.timestamp = report.timestamp;
                        rec.audio.jitter = report.jitter * 1000;
                        rec.audio.bytesReceived = report.bytesReceived;
                        rec.audio.packetsReceived = report.packetsReceived;
                        rec.audio.packetsLost = report.packetsLost;
                        break;
                    case 'video':
                        video_exist = true;
                        video.timestamp = report.timestamp;
                        video.jitter = report.jitter * 1000;
                        video.bytesReceived = report.bytesReceived;
                        video.packetsReceived = report.packetsReceived;
                        video.packetsLost = report.packetsLost;
                        video.frameWidth = report.frameWidth;
                        video.frameHeight = report.frameHeight;
                        video.framesPerSecond = report.framesPerSecond;
                        break;
                    }
                    break
                case 'remote-inbound-rtp':
                    switch (report.kind) {
                    case 'audio':
                        rec.audio.rtt = report.roundTripTime;
                        break;
                    case 'video':
                        rtt = report.roundTripTime;
                        break;
                    }
                    break;
                }
            })
            if (video_exist) {
                video.rtt = rtt;
                rec.video = video;
            } else {
                rec.video = null;
            }
            // Put hi priority message to log
            console.error(`StatRecord##${JSON.stringify(rec)}`);
            })
        }, interval);
    }

    sendDtmf(tones: string) {
        if (!this._dtmfSender) {
            this._dtmfSender = this._peerConnection?.getSenders()[0].dtmf;
        }
        this._dtmfSender?.insertDTMF(tones, DTMF_DURATION, DTMF_TONE_GAP);
    }
    
    muteAudio(mute: boolean) {
        if(this.localStream) {
            this.localStream.getAudioTracks()[0].enabled = !mute;
        }
    }
    
    muteRemote(mute: boolean) {
        if(this.remoteStream) {
            this.remoteStream.getAudioTracks()[0].enabled = !mute;
        }
    }
}
